import React, { useEffect, useRef, useState } from 'react'
import './login.css';
import * as CONSTANTS from "../CONSTANTS";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { login, reset } from '../reduxAuth/authSlice';
import { useDispatch, useSelector } from 'react-redux';

function Login() {

  const [erEmpty, setErEmpty]                          = useState("");
  const emailRef                                       = useRef();
  const passwordRef                                    = useRef();
 
  const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

  const navigate                                       = useNavigate();
  const dispatch                                       = useDispatch();

  useEffect(() => {

    if(isError){
      setErEmpty(message)
    }
  
    if(isSuccess || user){
        navigate('/');
      }

      dispatch(reset());

    },[user, isError, isSuccess, message, navigate, dispatch])

  const handleLogin = async (e) => {
    e.preventDefault();
  
        const email    = emailRef.current.value;
        const password = passwordRef.current.value;
 
      try{

          if((email !== "") && (password !== "")){
              let emailCheckTest = isValidEmail(email);

              console.log(emailCheckTest);
              if(emailCheckTest){
                       
                    const userData = {
                      "email": email,
                      "password": password
                    }        
                    
                    dispatch(login(userData));
                  
              }else {
                setErEmpty("Email not in a valid format.")
              }
             
          }else {
            setErEmpty("Email or password cannot be empty")
          }               
                  
      }catch(errsLog){
          console.log(errsLog);
          if(errsLog.response.status === 401){
            console.log(errsLog.response.data);
            setErEmpty(errsLog.response.data)
          }
      }
  }

  function isValidEmail(email){
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <div className="logo-base flexlog">
        <div className="log-start">
          <div className="lgin-spot">
             <div className="logowork">
                <img src="../../logo512.png" className="logo-on" alt="Picnick" />
             </div>
            <h3>Login</h3>
            <form encType="multipart/form-data">
              <div className="form-group frg">
                <div className="text-form">Email: </div>
                <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter Email Address" required/>
              </div>
              <div className="form-group frg">
                <div className="text-form">Password: </div>
                <input type="password" className="form-control ct-content wide100"  ref={passwordRef} placeholder="Enter Password" required/>
              </div>
              <div className="form-group frg">
                 <button className="btn btn-redish" onClick={handleLogin} disabled={isLoading}>Sign In</button>
              </div>
              <div className=" frg">
                <Link to="/sign-up">
                  Sign Up.
                </Link>
              </div>
            </form>
             <div className="frg gray-link mgtopMin35 flright">
                  <Link to="/forgot-password">
                  Forgot Password
                  </Link>
                </div>
            
            {
              erEmpty && (
                <div className="alert-area alert-danger">
                    {erEmpty}
                </div>
              )
            }
          </div>

         {
          isLoading && (
            <div className="img-source">
              <img src={CONSTANTS.MAJOR_PROCESSING} className="proc" />
            </div>
          )
         }
        </div>
        <div className="log-image-pot" style={{
             backgroundImage: `url(${CONSTANTS.LOGIN_BACKGROUND})` }}>      
        </div>
    </div>
  )
}

export default Login
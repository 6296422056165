import React, { useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import * as XLSX from 'xlsx';

function TBVAckerCSVCats({person}) {
    const [msgFeed, setMsgFeedback]                        = useState("");
    const [msgClass, setMsgClass]                          = useState("");

    const [pullContent, setPullContent]                    = useState([]);
    const [presentAction, setPresentationAct]              = useState(0);

    const [processing, setProcessing]                      = useState(false);

    const titleReadRef                                     = useRef();
    const startPullCodeRef                                 = useRef();
    const endPullCodeRef                                   = useRef();


    const handleAckermansSettings = async (e) => {
        e.preventDefault();  
        setProcessing(true);

        try{

                const titleRead = titleReadRef.current.value.replace('.csv','');
                const startRead = startPullCodeRef.current.value;
                const endRead   = endPullCodeRef.current.value;

                console.log(titleRead);
                console.log(startRead);
                console.log(endRead);

                const response = await axios.get(CONSTANTS.PICKYBIN +"&filename="+titleRead+"&start=" + startRead + "&end=" + endRead, {
                    headers: {
                        token: "Bearer "+ person.accessToken
                    }
                 });

                 //console.log(response.data);
                 if(response.data.status === 200){

                    setPullContent(response.data.content);

                 }else {
                    setMsgFeedback(response.data.message);
                    setMsgClass("alert-danger");
                 }
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }

    const exportExcelDocument = async () => {
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(pullContent);
    
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Ackermans_pull.xlsx");

    }
    
  return (
    <div className="tv-box">
        <h3>Ackermans Category Pulls</h3>

        <div className="base copy">            
            <div className="copy-box">
                <div className="border-barrel-acker">
                    <form onSubmit={handleAckermansSettings}>
                        <div className="flexme">
                            <div className="frm-ct">
                                The name of the file to be read*
                                <input type="text" className="form-control wid260" placeholder="CSV File Name" ref={titleReadRef} required/>
                            </div>
                            <div className="frm-ct">
                                Enter Start pull code*
                                <input type="text" className="form-control" placeholder="Start Code" ref={startPullCodeRef} required/>
                            </div>
                            <div className="frm-ct">
                                Enter End pull code*
                                <input type="text" className="form-control" placeholder="End Code" ref={endPullCodeRef} required/>
                            </div>
                        </div>
                        <div className="form-group pd10">
                            <button type="submit" className="btn btn-picky">Send</button>
                        </div>    
                    </form>
                </div>

                <hr />
                <div className="list-acker-content">
                    <div className="table-list">
                        {
                            (pullContent.length > 0) && (
                                <div className="pd10">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td>NO</td>
                                                <td>Code Ref</td>
                                                <td>Barcode</td>
                                                <td>Title</td>
                                                <td>Price</td>
                                                <td>et</td>
                                                <td>et2</td>
                                                <td>cost Price</td>
                                                <td>BIN</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                pullContent.map((logs, i) => {
                                                    return (<tr key={i} >                            
                                                        <td>{logs.count}</td>
                                                        <td>{logs.code_ref}</td>
                                                        <td>{logs.barcode}</td>
                                                        <td>{logs.title}</td>
                                                        <td>{logs.price}</td>
                                                        <td>{logs.et}</td>
                                                        <td>{logs.et2}</td>
                                                        <td>{logs.cost_price}</td>
                                                        <td>{logs.bin}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <button className="btn btn-picky" onClick={() => exportExcelDocument()}>DOWNLOAD CSV</button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        {
            msgClass && (
                <div className={"alert " + msgClass}>
                    {msgFeed}
                </div>
            )
        }
        {
            processing && (
                <div className="img-source">
                <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                </div>
            )
        }
    </div>
  )
}

export default TBVAckerCSVCats
import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function CrdbAckermansTot({
        personAdmin
    })
 {

    const [totalAckers, setTotalAckers]                 = useState(0);
    useEffect(() => {
        geAckerCount();
    },[])


    const geAckerCount = async () => {
   
        const res = await axios.get(CONSTANTS.API_URL +"pictures/totalackermans");
        console.log(res.data);
        setTotalAckers(res.data.total)
     
    }
  return (
    <div className="col-md-4 ">
        <div className="ac-fold color-box col-blue">
        Number of Ackermans products <div className="count-acker">{totalAckers}</div>
        </div>
    </div>
  )
}

export default CrdbAckermansTot
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgDelCategory(props) {
    const [mssage, setMssage]                       = useState("");
    const [msClass, setMsClass]                     = useState("");
    const [proc, setProc]                           = useState(false);

    const deleteCurrent = async () => {

       try{
        if(props.parentID === 0){
           //CATEGORY
            const catDel = await axios.delete(CONSTANTS.API_URL+"categories/terminate/" + props.currentCat._id, {
                    headers: {
                        token: "Bearer "+ props.person.accessToken
                    }
            });
            
            console.log(catDel.data)
            setMssage(catDel.data);
            setMsClass("alert-success");
        }else{
           //SUB CATEGORY
           console.log("delete sub catelogue");
           const catSubDel = await axios.put(CONSTANTS.API_URL+"categories/terminatesub/" + props.parentID, props.currentCat, {
                    headers: {
                        token: "Bearer "+ props.person.accessToken
                    }
            });
            
            console.log(catSubDel.data)
            setMssage(catSubDel.data);
            setMsClass("alert-success");
        }
            
       }catch(erDel){
            console.log(erDel);
            setMssage("Something went wrong.");
            setMsClass("alert-danger");
       }
    }
  return (
    <Dialog header="Confirm Delete" visible={props.delCategoryDialog} style={{ width: '50vw' }} modal={true} onHide={() => props.setDelCategoryDialog(false)}
    footer={
        <div>
            <Button className="btn btn-picky" label="Close" onClick={() => props.setDelCategoryDialog(false)} />
        </div>
    }>
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-12">
              
                <p><strong>{props.currentCat && props.currentCat.categoryName}</strong></p>
                <p>Delete the this category</p>

                <button className="btn btn-picky" disabled={proc} onClick={() => deleteCurrent()}>Delete</button>
                   <div className="pdtb10">
                    {mssage && (
                        <div className={"alert " + msClass}>
                            {mssage}
                        </div>
                    )}
                   </div>

                {
                    proc && (
                        <div className="img-source">
                           <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                        </div>
                    )
                }
            </div>
        </div>
    </Dialog>
  )
}

export default DlgDelCategory
import React, { useEffect } from 'react';
import Login from './pages/Login';
import Sidebar from "./components/Side/Sidebar";
import Tobnavbar from "./components/Top/Tobnavbar";
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function AppProtectedRoutes() {
    const {user}                                                               = useSelector((state) => state.auth);
    const navigate                                                             = useNavigate();

    useEffect(() => {

        if(!user){
            navigate("/login");
        }
    },[user, navigate])

  return (     
         <div className="dashingboard-outer">   
            {
                user ?  
                <div className="dashingboard">
                    <div className="side-dash">
                            <Sidebar member={user} />
                    </div>
                     <div className="main-dash-content">
                        <div className="top-nav">
                            <Tobnavbar />
                        </div>
                        <div className="main-content-arena">
                            <Outlet />
                        </div>
                    </div>
                </div>
                :

                <div className="cont-un-auth">
                    <Login />
                </div>
            } 
         </div>
  )
}

export default AppProtectedRoutes
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgAddCategoryImage(props) {

    const [processing, setProcessing]             = useState(false);
    const [imagepreview, setImagePreview]         = useState(CONSTANTS.PREVIEW_IMAGE);
    const [bannerURL, setBannerUrl]               = useState("-");
    const [imgUpdatedMsg, setImageUpdatedMsg]     = useState("");

    const mainImage = useRef();

    useEffect(()=> {
        /*
       console.log("Zaeeeeeeeuuuuummm");
       console.log(props.currentCat)
       */
       if(props.currentCat){
        //console.log("doooong doooong");
        setBannerUrl(props.currentCat.img_url || "-")
        setImageUpdatedMsg("");
       }

    },[props.currentCat]);

    const checkMainImage  = () => {
        if(mainImage.current.files[0]){
          //  console.log("Preview");
            setImagePreview(URL.createObjectURL(mainImage.current.files[0]));
        }
    }

    async function handleUploadBanner(e) {
        e.preventDefault();   
        setProcessing(true);   
        try{

            const formData = new FormData();

            formData.append("imgurl", mainImage.current.files[0]);
            formData.append("catid", props.currentCat._id);

            const respBann = await axios.post(CONSTANTS.API_URL+"categories/banner" ,formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    token: "Bearer "+ props.person.accessToken
                }
             });
            
            if(respBann.status === 200){

                //letstrade 
                var tradeBanner = {
                    "id" : props.currentCat.catId, 
                    "name" : props.currentCat.categoryName, 
                    "image_url" : respBann.data.updatedBanner.img_url,  
                }


                var dataTrade = JSON.stringify(tradeBanner);

                var config = {
                    method: 'put',
                    url: CONSTANTS.LETSTRADE1 + "product/update_category",
                    headers: { 
                        'Authorization': 'Bearer ' + CONSTANTS.LTTOKEN,
                        'Content-Type': 'application/json'
                    },
                    data : dataTrade
                  };

                const letsTradeBan = await axios(config);

                console.log("**********");
                //console.log(letsTradeBan);
                //console.log(letsTradeBan.data);
                if(letsTradeBan.data.success){
                    setImageUpdatedMsg("Banner has been added.");
                    mainImage.current.value ="";
                    setTimeout(function() {
                        setImageUpdatedMsg("");
                        setImagePreview(CONSTANTS.PREVIEW_IMAGE);                
                    }, 3000);
                }

            }
            
            setProcessing(false); 
        }catch(err){
            console.log(err);
            setProcessing(false); 
        }
    }

  return (
    <Dialog header="Update img url" visible={props.dsplyImgCategoryDialog} style={{ width: '50vw' }} modal={true} onHide={() => props.setDsplyImgCategoryDialog(false)}
        footer={
            <div>
                <Button className="btn btn-picky" label="Close" onClick={() => props.setDsplyImgCategoryDialog(false)} />
            </div>
        }>
        <div className="mgtop20">
           <div className="item-img">
               {
                    (bannerURL !== "-") && (
                        <img src={bannerURL} className="banner-itme" />
                    )
               }
           </div>
           <div className="item-img">
             <h4>Add Image</h4>
             <form onSubmit={handleUploadBanner}>
        
                    <div className="form-group">                            
                        <img className="prevban" src={imagepreview} alt="PickNick Preview image" />
                                        
                        <div className="lbldesc">Main Image*: </div>
                        <input type="file" className="form-control w80" onChange={() => checkMainImage()} ref={mainImage}  accept="image/*" required/>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-picky" disabled={processing}>Submit Image</button>
                    </div>
                
            </form>                

                {
                    processing && (
                        <div className="img-source">
                            <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                        </div>
                    )
                }
                {
                    imgUpdatedMsg && (
                        <div className="mgtop20 alert alert-success">
                            {imgUpdatedMsg}
                        </div>
                    )
                }
           </div>
        </div>
    </Dialog>
  )
}

export default DlgAddCategoryImage
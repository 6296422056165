import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgTagAdd({showDialogueTags, setShowDialogueTags, currentProduct, personAdmin}) {


    const [myTag, setMyTag]                       = useState([]);
    const [message, setMessage]                   = useState("");
    const [clmess, setClMessage]                  = useState("");

    function handleKeyDown(e) {
       
        if(e.key !== 'Enter') return
        const value = e.target.value
        if(!value.trim()) return
    
          setMyTag([...myTag, value.toLowerCase()])
          e.target.value = ''
          e.preventDefault();
    }
  
    const removethis = (place, index) => {
         
        setMyTag([
            ...myTag.slice(0, index),
            ...myTag.slice(index + 1)
          ]);
    }

    const saveTagsList = async () => {
        //setShowDialogueTags(false)
        setClMessage("");
        setMessage("");

        if(myTag.length > 0){
          
            const stringTags = myTag.toString();
           
            const res = await axios.put(CONSTANTS.API_URL +"tags/tagfilter", {
                newtags: stringTags,
                currproduct: currentProduct
            }, {
                headers: {
                    'token': 'Bearer '+ personAdmin.accessToken, 
                }
            });

            
            if(res.status === 201){
                window.location.reload();
            }else {
                setClMessage("alert-danger");
                setMessage("Sorry could not save the tags, please try again later");                
            }

        }else {
            setClMessage("alert-warning");
            setMessage("Please add tags first.");
            setTimeout(function() {
                setClMessage("");
                setMessage("");
            }, 5000);
        }
    }

  return (
    <Dialog header="Tags" visible={showDialogueTags} onHide={() => setShowDialogueTags(false)} style={{ width: '50vw' }}
        footer={
        <div>
        
            <Button className="btn btn-picky" label="Save" onClick={() => saveTagsList()} />
        </div>
        }>
        <div className="modal-body-content">
            <div className="flexme">
               <div className="spaceshare2 ">
                   <div className="fr-group">
                        <div className="tg-space"><strong>{myTag.length}</strong> </div>
                        <div className="lbldesc">Add Tags, then click enter: </div>
                        <div className="tags-input-container">

                            {
                                myTag.map((tag, index) => (
                                <div className="tag-item" key={index}>
                                    <span className="text">{tag}</span>
                                    <span className="close" onClick={() => removethis(tag, index)}>x</span>
                                </div>
                                ))
                            }
                            <input onKeyDown={handleKeyDown} type="text" className="edit-in" placeholder="Add Tag..." />                    
                        </div>
                        <div className="pdtop20">
                            {message && (
                                <div className={"alert " + clmess}>
                                    {message}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
  )
}

export default DlgTagAdd
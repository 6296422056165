import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgCatUsers({showDialogueUsers, setShowDialogueUsers, addUserToListedUsers, removeUserToListUsers, members, memeberIds} ) {
  
    const [searchMsgFdb, setSearchMsgFdb]       = useState("");
    const [foundUsersList, setFoundUserList]    = useState([]);
    const searchUserRef    = useRef();


    const searchEnteredString = async () => {
        if(searchUserRef.current.value.length > 3){            
            try{
                const res = await axios.get(CONSTANTS.API_URL +"users/search?searchfield=" + searchUserRef.current.value);
                //console.log(res);
                //console.log(res.data);
                //console.log("THE MEMBER IDS");
                //console.log(memeberIds);
                const allFoundUsers = res.data;

                //console.log(allFoundUsers);
                //filter the users that already exist.
                var filterAllExistingUsers = [];
                if(memeberIds){

                   // console.log("MEmber ID found " + memeberIds);
                    var filterAllExistingUsers = allFoundUsers.filter(function(foundUser) {
                        return !memeberIds.includes(foundUser._id);
                    });
                }else {
                    //console.log("MEmber ID NOt found YAAA " + memeberIds);
                    filterAllExistingUsers = allFoundUsers;
                }
                
                //console.log("LEFT USERS");
                //console.log(filterAllExistingUsers);

                
                setFoundUserList(filterAllExistingUsers);
                
            }catch(ers){
                console.log(ers);
            }
            setSearchMsgFdb("");
        }else {
            setSearchMsgFdb("More than 3 characters required in order to search.");
        }
    }
  return (
    <div className="block-comp">
     
         <Dialog visible={showDialogueUsers} onHide={() => setShowDialogueUsers(false)} style={{ width: '50vw' }}
            footer={
            <div>
                {/* link button */}
              
                <Button className="btn btn-picky" label="Close" onClick={() => setShowDialogueUsers(false)} />
            </div>
            }>
            <div className="modal-body-content">
                <div className="flexme">
                    <div className="spaceshare2 ">
                        <div className="list-modal-items">
                            <h3>Current Users</h3>
                     
                                
                            <div className="rectang-side-scroll">
                                {
                                    members && (
                                        <div className="list-find-searchs">
                                            <ul className="ls-fd-search no-list-style">
                                            {
                                                members.map((member) => {
                                                    return (<li className="list-item-block srch-list-unfound" key={member._id}>
                                                    <div className="flexme fl-srch fl-srch2">
                                                        <div className="image-profile">
                                                            {
                                                            (member.profilePic == "-") ?
                                                                <div  className="sm profile-src" style={{ backgroundImage: `url("https://newclient.cjdsa.co.za/images/base/profile.jpg")`}} >
                                                                </div>
                                                            :
                                                            <div  className="sm profile-src" style={{ backgroundImage: `url(${member.profilePic})`}} >
                                                            </div>
                                                                
                                                            }
                                                        </div>
                                                        <div className="content-profile">
                                                            <div className="titling">
                                                                <span>{member.name}</span> <span>{member.surname}</span>
                                                            </div>
                                                            <div className="pro-small">
                                                                <div>{member.email}</div>
                                                                <div>{member.profession}</div>

                                                            </div>
                                                        </div>
                                                        <div className="link-srch-button">
                                                            <button 
                                                                className="btn btn-red-outline btsm1"
                                                                onClick={() => removeUserToListUsers(member)}> Remove</button>
                                                        </div>
                                                    </div>
                                                </li>)
                                                })
                                            }      
                                            </ul>
                                        </div>
                                        
                                    )
                                }
                            </div>



                        </div>
                        
                    </div>
                    <div className="spaceshare2">
                        <div className="list-modal-items">
                            <h3>Search For User</h3>
                            <div className="search-block">
                                <div className="flexme">
                                    <input type="text" className="form-control search-pers" ref={searchUserRef} />
                                    <button className="btn btn-picky btn-side-search" onClick={searchEnteredString}><FontAwesomeIcon icon={faSearch} /></button>
                                </div>
                                <div className="search-list">
                                    {searchMsgFdb && (
                                        <div className="alert-area alert-warning">
                                            {searchMsgFdb}
                                        </div>
                                    )}

                                    {foundUsersList && (
                                        <div className="list-find-searchs">
                                            <ul className="ls-fd-search no-list-style">
                                                {foundUsersList.map((list) => {
                                                    return (<li className="list-item-block srch-list-unfound" key={list._id}>
                                                        <div className="flexme fl-srch fl-srch2">
                                                            <div className="image-profile">
                                                                {
                                                                   (list.profilePic == "-") ?
                                                                      <div  className="sm profile-src" style={{ backgroundImage: `url("https://newclient.cjdsa.co.za/images/base/profile.jpg")`}} >
                                                                      </div>
                                                                   :
                                                                   <div  className="sm profile-src" style={{ backgroundImage: `url(${list.profilePic})`}} >
                                                                   </div>
                                                                      
                                                                }
                                                            </div>
                                                            <div className="content-profile">
                                                                <div className="titling">
                                                                    <span>{list.name}</span> <span>{list.surname}</span>
                                                                </div>
                                                                <div className="pro-small">
                                                                    <div>{list.email}</div>
                                                                    <div>{list.profession}</div>

                                                                </div>
                                                            </div>
                                                            <div className="link-srch-button">
                                                                <button 
                                                                    className="btn btn-green-outline btsm1"
                                                                    onClick={() => 
                                                                        {
                                                                            addUserToListedUsers(list);
                                                                            searchEnteredString();
                                                                        }
                                                                    }> Add</button>
                                                            </div>
                                                        </div>
                                                    </li>)
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
  )
}

export default DlgCatUsers
import React from 'react';
import * as CONSTANTS from "../CONSTANTS";

function TBVFormGal() {
  return (
    <div className="tv-box">
        <h3>Formulate Gallery</h3>
        <div className="base copy">
            <div className="copy-box">
                A table of all the pictures that will pulled in your catalogue will be listed.
            </div>
        
            <div className="copy-box">           
              <img src={CONSTANTS.TOKEN_IMAGE}  className="img-rep wfull" /><br/>
              <p>You then use this token to make a ge request to your list of catalogues. Your token will bring back the catalogue that you have access to and from the you just need 
              to make a get request to all your pictures</p>
            </div>
        </div>
    </div>
    )
}

export default TBVFormGal
import React, { useEffect, useRef, useState } from 'react'
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { RadioButton } from 'primereact/radiobutton';
import CrdbAckermansTot from '../components/CrdbAckermansTot';
import { useSelector } from 'react-redux';
//import CrdbAckerTransfer from '../components/CrdbAckerTransfer';

function Ackermans() {
  const {user}                                              = useSelector((state) => state.auth);

  const [stringSearch, setStringSearch]                       = useState('');
  const [ackerCollection, setAckerCollection]                 = useState([]);
  const [currentProd, setCurrentProd]                 = useState({});
  const [searchProc, setsearchProc]                   = useState(false);
  const [listDialogue, setlistDialogue]               = useState(false);

  const handleChangeSearch = (event) => {
    // 👇 Get input value from "event"
    setStringSearch(event.target.value);
    console.log(event.target.value);

    if(event.target.value.length > 2){
     
      executSearchNow(event.target.value);
       //search execution
    }else {
      setsearchProc(false);
      setlistDialogue(false);
    }
  };

  const executSearchNow = async (content) => {
    console.log("%%%%%%%%%");
    console.log(content);
   try{
        setsearchProc(true);
        setlistDialogue(true);
        /*
          const resAutoAcker = await axios.get(CONSTANTS.API_URL +"pictures/autoackerm?searchString=" + content, {
              headers: {
                  token: "Bearer "+ personAdmin.accessToken
              }
          });
        */

        setAckerCollection([]);
        const resAutoAcker = await axios.get(CONSTANTS.API_URL +"pictures/autoackerm?searchString=" + content);
        console.log(resAutoAcker.data);
        setAckerCollection(resAutoAcker.data);
        setsearchProc(false);
        
   }catch(error){
        setsearchProc(false);
        setlistDialogue(false);
      console.log(error);
   }
  }

  const updateSelectedProduct = (log) => {
    console.log(log)
    setCurrentProd(log);
    //checkBarcodeData(log.barcode)
  }
  return (
    <div className="main-standard" >                 
        <h2 className="lbl-title-page">Ackermans</h2>           
        <div className="ct-section">      
            <div className="card">
                <div className="card-body">               
                        <div className="row">
                          <CrdbAckermansTot personAdmin={user}/>
                          {
                          /*
                          <CrdbAckerTransfer personAdmin={user}/>
                          */
                          }
                        </div>

                        <div className="row">
                            <div className="col-md-7">
                                <div className="special-search pd10">
                                  <h3>Auto Search</h3>
                                  <div className="inp-text">
                                    <input
                                        type="text"
                                        className="searchmain"
                                        id="message"
                                        name="message"
                                        onChange={handleChangeSearch}
                                      />
                                    </div>
                                    {
                                      listDialogue && (
                                        <div className="dialog-drop">
                                                {
                                                  searchProc &&
                                                    (<div className="process-found">loading...</div>)
                                                    
                                                } 
                                                {
                                                  ackerCollection && (
                                                  <div className="flexme">
                                                      <div className="overflow-ob-list">
                                                        <ul className="no-bullet bulle-select">
                                                            {
                                                              ackerCollection.map((logs, i) => {
                                                                return (<li className="fixr-block-item" key={i}>
                                                                      <button className="new-style-button" onClick={() => updateSelectedProduct(logs)}>
                                                                          <div className="numbering">{i+1}</div> {logs.title}
                                                                      </button>
                                                                    
                                                                  </li>
                                                                  )
                                                              })
                                                            }
                                                          </ul>
                                                      </div>
                                                          <div className="selected-item pd10">
                                                          <h4> Select product</h4>
                                                            {
                                                              (Object.keys(currentProd).length !== 0) && (
                                                                <div className="cont-select">
                                                                  <table className="table table-striped">
                                                                    <tr>
                                                                      <td>
                                                                        Title
                                                                      </td>
                                                                      <td>:</td>
                                                                      <td>{currentProd.title }     </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td>
                                                                        Barcode
                                                                      </td>
                                                                      <td>:</td>
                                                                      <td>{currentProd.barcode }     </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td>
                                                                        Price
                                                                      </td>
                                                                      <td>:</td>
                                                                      <td>{currentProd.price / 100}     </td>
                                                                    </tr>
                                                                  </table>
                                                                </div>
                                                              )
                                                            }

                                                            {

                                                            }
                                                          </div>
                                                  </div>   
                                                  )
                                              }
                                          </div>
                                      )
                                    }

                                </div>
                            </div>
                            <div className="col-md-5">
                                  <div className="">
                                       <h3>Bulk Upload</h3>
                                  </div>
                            </div>
                        </div>
                  </div>
            </div>
        </div>
    </div>
  )
}

export default Ackermans
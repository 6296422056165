import React from 'react'

function TBVPictures() {
  return (
    <div className="tv-box">
        <h3>Picures API</h3>
        <div className="base copy">
            <div className="copy-box">
                This is the base url structure:
            </div>
            <code>
            /api/pictures
            </code>
            <div className="copy-box">
                <h4>Getting Pictures</h4>
                In order to make a request that will pull all the pictures
            </div>
        </div>
    </div>
  )
}

export default TBVPictures
import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Taxonomy() {
  const {user}                                                               = useSelector((state) => state.auth);

  const [taxonomyItems, setTaxonomyItems]       = useState([]);
  const [products, setProducts]                 = useState([]);
  const [tagType, setTagType]                   = useState("");
  const [tagClick, setTagClick]                 = useState(-1);
  const [processing, setProcessing]             = useState(false);
  const [search, setSearch]                     = useState("");
  
  useEffect(() => {
      getAlltaxonomy();
  },[])

  const getAlltaxonomy = async () => {
    setProcessing(true);
    try {
      
      const res = await axios.get(CONSTANTS.API_URL +"tags/list", {
          headers: {
              token: "Bearer "+ user.accessToken
          }
      });
      //console.log(res.data);
      setTaxonomyItems(res.data);
      setProcessing(false);
    } catch (err) {
      setProcessing(false);
      console.log(err);
      if(err.message === "Request failed with status code 403"){
        localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
        document.location.href="/login";
       }
      console.log("ERRRRRRR")
    }

  }
  const listtagProducts = async (taglog, i) => {
    setProcessing(true);
    try{

      const resDet = await axios.get(CONSTANTS.API_URL +"tags/tagpic/" + taglog.tagName, {
          headers: {
              token: "Bearer "+ user.accessToken
          }
      });

      //console.log(resDet.data);
      setProducts(resDet.data);
      setTagType(taglog.tagName);
      setTagClick(i);
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  } 
  return (
    <div className="main-standard" >                 
       <h2 className="lbl-title-page">Tags</h2>
       <div className="list-taxonomy">
           <div className="card">
              <div className="card-body">
                <div className="flexme">
                   <div className="initial-list-cext">
                       <div className="search-tag">
                       <input type="text" className="form-control" onChange={(e) => setSearch(e.target.value)} placeholder="Search Tag" />
                       </div>
                       <div className="list-tags-now">
                        {
                        
                          taxonomyItems && (
                              taxonomyItems
                              .filter((item) => {
                                return search.toLowerCase() === ''
                                ? item
                                : item.tagName.toLowerCase().includes(search)
                              })
                              .map((logs, i) => {
                                  return (<div className={"mp-tg-item " + (tagClick === i && "active-click-tag")} key={i} >                            
                                          <div className="tag-base-links" onClick={() => listtagProducts(logs, i)}>
                                              {logs.tagName}
                                          </div>
                                      </div>
                                  )
                              })
                            )
                        }
                       </div>
                   </div>

                   <div className="roast-list-cext">
                        <div className="">
                        {
                          tagType && (<div className="tag-title">TAG: <span className="capital">{tagType}</span></div>)
                        }
                        {                        
                            products && (
                                <div className="row">
                                      {
                                        products.map((prod, i) => {
                                              return (<div className="col-md-4" key={i}>                            
                                                          <div className="tag-base-links-min" >
                                                              <div className="">
                                                                <strong>{prod.barcode}</strong>
                                                              </div>
                                                              <div className="">
                                                                   {prod.title}
                                                              </div>
                                                              <img src={prod.imgurl} className="img-reps wfull" />
                                                              <Link to={"/photo/"+ prod._id} className="btn btn-picky btsm1 mr-2">View</Link>
                                                          </div>
                                                      </div>
                                                    )
                                          })
                                      }
                                </div>
                                )
                        }
                        </div>
                   </div>

                </div>
              </div>
              {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                            </div>
                        )
                     }
           </div>

       </div> 
    </div>
  )
}

export default Taxonomy
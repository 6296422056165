import React, {useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import * as XLSX from 'xlsx';

function TBVImportExcel({person}) {
    const [fileContent, setFileContent]                           = useState([]);
    const [uploadingBut, setUploadingBut]                         = useState(false);
    const [proc, setProc]                                         = useState(false);

    const [foundAthenaem, setFoundAthenaem]                       = useState("");
    const [foundAthenaemNt, setFoundAthenaemNt]                   = useState("");
    const [exists, setExists]                                     = useState("");
    const [transPictures, setTransferPictures]                    = useState([]);
    const [totalAthenaeums, setTotalAthenaeums]                    = useState();
    const [messageBack, setMessBack]                              = useState("");

    const urlToPostRef                                            = useRef();
    const tokenAuth                                               = useRef();

    useEffect(() => {
        getTotalAthenaeumsMethod();
    }, [])

    const getTotalAthenaeumsMethod = async () => {
        try{

            const countResp = await axios.get(CONSTANTS.API_URL+"pictures/count/athenaeum/total" , {
                        headers: {
                            token: "Bearer "+ person.accessToken
                        }
                });
                
                setTotalAthenaeums(countResp.data.total);
        }catch(err){
            console.log("getTotalAthenaeumsMethod");
            console.log(err);
        }
    }

    const handleFileUpload = (e) => {
        if(e.target.files.length > 0 ){
            console.log("File uplood");
            setUploadingBut(true);
            const reader = new FileReader();
            reader.readAsBinaryString(e.target.files[0]);
            reader.onload = (e) => {
                const data       = e.target.result;
                const workbook   = XLSX.read(data, {type: "binary"});
                const sheetName  = workbook.SheetNames[0];
                const sheet      = workbook.Sheets[sheetName];
                const parsedData = XLSX.utils.sheet_to_json(sheet);
                setFileContent(parsedData);  

                console.log(parsedData);
            }
        }else {
            console.log("Empty");
        }
    }

    const handleTransferOfDetails = async (e) => {
        e.preventDefault();  
        setProc(true);
        try{
            const fileData = {
                "fileData": fileContent
            }
           const transferDetails = await axios.post(CONSTANTS.API_URL+"pictures/transfer/fromathenaeum/withexcel", fileData, {
                    headers: {
                        token: "Bearer "+ person.accessToken
                    }
            });

            //console.log(transferDetails);
            //console.log(transferDetails.data);
            
            //////
            if(transferDetails.status == 200){
                setFoundAthenaem("Total transfers: " + transferDetails.data.found );
                setFoundAthenaemNt("Total untransferred: " + transferDetails.data.not_found);
                setTransferPictures(transferDetails.data.passed_pictures);
                setExists("Total pictures that are already ins the system: " + transferDetails.data.exist)

                setFileContent([]);
                setUploadingBut(false);
            }else {
                setMessBack("Something went wrong")
            }
            
            //////
            setProc(false);
        }catch(error){
            console.log(error);
            setProc(false);
            setMessBack("Something went wrong")
        }
    }

    return (
        <div className="tv-box">
           <h3>Add Excel Sheet</h3>
            <div className="base copy"> 
              <div className="col-display">
                Column Structure
                <div className="col-items-display">
                    <span>barcode</span>
                    <span>title</span>
                    <span>description</span>
                    <span>fulldescription</span>
                </div>
                {
                    totalAthenaeums && (
                        <p>Total Athenaems currently ({totalAthenaeums})</p>
                    )
                }
              </div>
               <div className="rs row">
                        <div className="col-md-6">
                            <div className="frm-data excel-forms">
                                Add Excel Sheet                              
                                <div className="form-group">
                                    <input 
                                        type="file"
                                        clasName="form-control"
                                        accept=".xlsx, .xls"
                                        onChange={handleFileUpload}
                                        />
                                </div>
                                
                                <div className="table-excell-display mgtop10">
                                        {
                                            (fileContent.length > 0) && (
                                                <table className="table table-striped">
                                                      <thead>
                                                        <tr>
                                                            <th>Barcode</th>
                                                            <th>Title</th>
                                                            <th>Description</th>
                                                            <th>Full Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            fileContent.map((row, i) => {
                                                                return (
                                                                   <tr key={i} >                            
                                                                          <td>{row.barcode}</td>
                                                                          <td>{row.title}</td>
                                                                          <td>{row.description}</td>
                                                                          <td>{row.fulldescription}</td>
                                                                    </tr>
                                                                )
                                                              }
                                                            )      
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                </div>
                                <div className="sub-miit mgtop10">
                                    {
                                        uploadingBut && (
                                            <div className="script-form">
                                                <form onSubmit={handleTransferOfDetails}>
                                                 
                                                    <div className="form-group">                                                
                                                            <div className="button-execs">
                                                                <button className="btn btn-picky mr-2" >Transfer Pictures</button>         
                                                            </div>
                                                    </div>
                                                </form>
                                            </div>
                                        )
                                    }
                                    {
                                        proc && (
                                            <div className="img-source">
                                              <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="sub-process mgtop10">
                                        {
                                            foundAthenaem && (
                                                <div className="route-alert">
                                                    <p className="lf-found">{foundAthenaem}</p>
                                                    <p className="lf-not-found">{foundAthenaemNt}</p>
                                                    <p className="lf-ound">{exists}</p>
                                                </div>
                                            )
                                        }
                                        {
                                            messageBack && (
                                                <div className="alert alert-danger">
                                                    {messageBack}
                                                 </div>
                                            )
                                        }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="list-blow">
                            

                            </div>
                        </div>
               </div>
            </div>
        </div>
    )
}

export default TBVImportExcel
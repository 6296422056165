import React, { useEffect, useRef, useState  } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';

function DlgEditPhotoCategory({setShowDialPhotoCategroy, showDialPhotoCategory, photoid, pbarcode, personAdmin, setUpdateMe, updateMe}) {

    const [photoCategory, setCategoryList]             = useState();
    const [selectedCat, setSelectedCat]                = useState();
    const [selectedCatOb, setSelectedCatOb]            = useState();
    const [msgCopy, setMsgCopy]                        = useState("");
    const [errCopy, setErrCopy]                        = useState("");
    const [processing, setProcessing]                  = useState(false);

    useEffect(() => {
        getCategoryList();
    },[]);

    const getCategoryList = async () => {

        try{
            const resCategories = await axios.get(CONSTANTS.API_URL+"categories/list", {
                headers: {
                    token: "Bearer "+ personAdmin.accessToken
                }
            });

            //console.log(response.data.data);
            setCategoryList(resCategories.data.data);
            //console.log("######*********")
        }catch(errs){
            console.log(errs)
        }
    }

    const updateSelectedCategory = async (catValue, catObject) => {
        setSelectedCat(catValue);
        setSelectedCatOb(catObject);
    }
    
    const saveCategoryToProduct = async () => {
        setProcessing(true);
       /*
        console.log("Selected Category");
        console.log(selectedCatOb);
        console.log("Photo ID");
        console.log(photoid);
       */
       try{

                if(selectedCatOb){
                    setMsgCopy("");
                    setErrCopy("");
                    var picPhotoCat = {
                        "category": selectedCatOb,
                        "photoid" : photoid
                    }
                    //picnic
                    const catPhoto = await axios.put(CONSTANTS.API_URL+"pictures/setcaegory", picPhotoCat,{
                        headers: {
                            token: "Bearer "+ personAdmin.accessToken
                        }
                    });
                    /*
                    console.log("DDDSSSGGGOOOODDDD");
                    console.log(catPhoto.data);
                    console.log("Barcode : " + pbarcode);
                    */
                   
                    //letstrade
                    //6006486031044
                    //UPDATE CATEGORY ON LETSTRADE OF THE PRODUCT
                    //LETSTRADE START
                    //GET OBJECT USING BARCODE
                    const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + pbarcode, {
                            headers: {               
                                'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                            }
                        });
                        //UPDATE PRODUCT
                        if(resPicsTradeBarcode.data.success === true){

                            var product_id = resPicsTradeBarcode.data.content.product_list[0]._id;   
                            var tradeUpdate = {
                                "id": product_id,
                                "category": {
                                    "id":selectedCatOb.catId,
                                    "name":selectedCatOb.name,
                                    "description":selectedCatOb.description,
                                    "image_url":selectedCatOb.image_url,
                                }
                            }

                            var dataTrade = JSON.stringify(tradeUpdate);
                            /////
                            
                                var configUpdate = {
                                    method: 'put',
                                    url: CONSTANTS.LETSTRADE1 + 'product/update_product',
                                    headers: { 
                                        'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN, 
                                        'Content-Type': 'application/json'
                                    },
                                    data : dataTrade
                                };
                                let execProd = await axios(configUpdate);
                                //console.log(execProd);
                                console.log(execProd.data);
                            setMsgCopy("The category has been set");
                            setErrCopy("alert-success");
                            setUpdateMe(updateMe + 1);
                        }else {
                            setMsgCopy("The category has been set, but not synced. Please try again later");
                            setErrCopy("alert-warning");
                        }
                    //LETSTRADE END
                    
                }else {
                    setMsgCopy("Please select a category first.");
                    setErrCopy("alert-danger");
                }
                setProcessing(false);
       }catch(errCat){
          console.log(errCat);
          setMsgCopy("Something went wrong, please try again later.");
          setErrCopy("alert-danger");
          setProcessing(false);
       }
    }
  return (
    <div className="block-comp">
        <Dialog header="Photo Category Edit"  visible={showDialPhotoCategory} onHide={() => setShowDialPhotoCategroy(false)} style={{ width: '50vw' }}
                footer={
                <div>                  
                    <Button className="btn btn-picky" label="Close" onClick={() => setShowDialPhotoCategroy(false)} />
                </div>
                }>
                <div className="modal-body-content">
                    <div className="cat-side-block">
                      <button  className="btn btn-picky btsm1" disabled={processing} onClick={() => saveCategoryToProduct()}>Save Category</button>
                      <div className="pdtb10">
                            {
                                msgCopy && (
                                    <div className={"alert " + errCopy}>
                                        {msgCopy}
                                    </div>
                                )
                            }
                      </div>
                    </div>
                    <div className="cate-item">
                    {
                        photoCategory && (
                            <div className="cat-boxer select-photo-rad">
                                {
                                    photoCategory.map((catitem, indi) => {
                                        return (
                                            <div className="main-cat-item" key={catitem._id}>
                                                <div className="field-radiobutton main-cat" >
                                                        {/*<RadioButton inputId={"catego" + indi} name="catego" value={catitem.categoryName} onChange={(e) => updateSelectedCategory(e.value, catitem)} checked={selectedCat === catitem.categoryName} /> */}
                                                        <label htmlFor={"catego" +indi}>{catitem.categoryName}</label>  
                                                </div>
                                                {(catitem.sub_categories.length > 0) && (
                                                            <div className="sub-cat-container"  >
                                                                {
                                                                catitem.sub_categories.map((catSub, i) => {
                                                                    return (
                                                                    <div className="sub-cat" key={catSub.catId} >                                                                    
                                                                        <RadioButton inputId={"subcatego" + ""+ indi +""+i} name="subcatego" value={catSub.name} onChange={(e) => updateSelectedCategory(e.value, catSub)} checked={selectedCat === catSub.name} />
                                                                        <label htmlFor={"subcatego" + ""+ indi +""+i}>{catSub.name}</label>
                                                                    </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        )}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    </div>
                   
                    {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                    }
                </div>
        </Dialog>
    </div>
  )
}

export default DlgEditPhotoCategory
import React, { useRef, useState  } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';


function DlgEditPhoto({setShowDialPhoto, showDialPhoto, setSelectedPhotoInfo, selectedPhotoInfo, personAdmin}) {

    const barcodeRef                             = useRef();
    const titleRef                               = useRef();
    const onlineDescRef                          = useRef();
    const descriptRef                            = useRef();
    const sizeRef                                = useRef();
    const unitRef                                = useRef();
    const presentationRef                        = useRef();
    const draftmodeRef                           = useRef();

    const [processing, setProcessing]                            = useState(false);

    const [msgFeedback, setMsgFeedback]                          = useState("");
    const [msgClass, setMsgClass]                                = useState("");

    const [sideMsgeFeed, setSideMsgFeed]                         = useState("");
    const [sMsgClass, setSMsgClass]                              = useState("");

    
    const deleteThisField = async () => {
       // console.log("Delete this guy");
       // console.log(selectedPhotoInfo);

        try{

            setMsgFeedback("");
            setMsgClass("");
         
               
               const response = await axios.delete(CONSTANTS.API_URL+"pictures/terminate/" + selectedPhotoInfo._id, {
                    headers: {
                        token: "Bearer "+ personAdmin.accessToken
                    }
                });
               //console.log(response.data);
               setMsgFeedback(response.data);
               setMsgClass("alert-warning");
               setProcessing(false);
         
              setSelectedPhotoInfo([]);

        }catch(err){
            setMsgFeedback("Something went wrong, please try again later");
            setMsgClass("alert-danger");
            setProcessing(false);
            //console.log(err);
        }
    }
    async function handleFormUpdatePhoto(e) {
        e.preventDefault();  
        setProcessing(true);   

        try{

            setMsgFeedback("");
            setMsgClass("");
            let draft = false;

            if(draftmodeRef.current.value === "true"){
                draft = true
            }
            
            const userOb = {
                "title": titleRef.current.value,
                "onlineDesc" : onlineDescRef.current.value,
                "description" : descriptRef.current.value,
                "size" : sizeRef.current.value,
                "unit" : unitRef.current.value,
                "presentation" : presentationRef.current.value,
                "draftmode" : draft,
                "photoid" : selectedPhotoInfo._id
               }

               //console.log(userOb);
              
               const response = await axios.put(CONSTANTS.API_URL+"pictures/updatepicture", userOb, {
                    headers: {
                        token: "Bearer "+ personAdmin.accessToken
                    }
                });
               console.log(response.data);
               setMsgFeedback("Picture updates have been updated.");
               setMsgClass("alert-success");
               
              
               //setSelectedPhotoInfo(response.data);
               
               setProcessing(false);

        }catch(err){
            setMsgFeedback("Something went wrong, please try again later");
            setMsgClass("alert-danger");
            setProcessing(false);
            //console.log(err);
        }
            
    }

    const templateDraftStatusOption = (roleItem) => {
       //console.log(roleItem);
       //console.log("paaaaa")
        
        switch(roleItem) {
            case true:
              return <select ref={draftmodeRef} className="form-control wide100" >
                        <option value="true">Hidden</option>
                        <option value="false">Visible</option>
                    </select>;
         
            default:
                return <select ref={draftmodeRef} className="form-control wide100" >                        
                          <option value="false">Visible</option>
                          <option value="true">Hidden</option>                            
                    </select>;
          }
        //setSelectedPhotoInfo(rowData);
      }


  return (
    <div className="block-comp">
        <Dialog header="Photo Edit"  visible={showDialPhoto} onHide={() => setShowDialPhoto(false)} style={{ width: '50vw' }}
            footer={
            <div>
                {/* link button */}
              
                <Button className="btn btn-picky" label="Close" onClick={() => setShowDialPhoto(false)} />
            </div>
            }>
                  <div className="modal-body-content">
                     <div className="ph-cont">
                    
                        <div className="picture-quick-edit ">
                          
                            <button className="btn btn-picky btsm1" disabled={processing} onClick={deleteThisField}>Delete</button>    

                            <form onSubmit={handleFormUpdatePhoto}>
                                <div className="form-group">
                                      <div className="lbldesc">Barcode:</div>
                                      <input type="text" className="form-control wide100" ref={barcodeRef} defaultValue={selectedPhotoInfo.barcode} readOnly={true} required/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Title:</div>
                                      <input type="text" className="form-control wide100" ref={titleRef} defaultValue={selectedPhotoInfo.title} required/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Online Description:</div>
                                      <textarea className="form-control wide100 heightf100" ref={onlineDescRef}  defaultValue={selectedPhotoInfo.onlineDesc}></textarea>                                        
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Description:</div>
                                      <textarea className="form-control wide100 heightf150" ref={descriptRef}  defaultValue={selectedPhotoInfo.description}></textarea>                                        
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Size:</div>
                                      <input type="text" className="form-control wide100" ref={sizeRef} defaultValue={selectedPhotoInfo.size} />
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Unit:</div>
                                      <input type="text" className="form-control wide100" ref={unitRef} defaultValue={selectedPhotoInfo.unit} />
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Presentation:</div>
                                      <input type="text" className="form-control wide100" ref={presentationRef}   defaultValue={selectedPhotoInfo.presentation}/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Draft Status:</div>
                                      {templateDraftStatusOption(selectedPhotoInfo.draftmode)}
                                </div>
                              
                                <div className="form-group">
                                      <div className="button-execs">
                                            <button type="submit" className="btn btn-picky mr-2" disabled={processing}>Update</button>         
                                       </div>
                                </div>
                            </form>

                            <div className="">
                                {
                                  msgFeedback && (
                                    <div className={"alert-area " + msgClass}>
                                        {msgFeedback}
                                    </div>  
                                  )
                                }
                          </div>
                        </div>
                        
                     </div>
                     {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                    }
                  </div>
            </Dialog>
    </div>
  )
}

export default DlgEditPhoto
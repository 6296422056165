import React, { useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUpload} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { useSelector } from 'react-redux';

function Profile() {
  const {user}                                                               = useSelector((state) => state.auth);
  
  const [btnSaveSwitch, setBtnSaveSwitch]              = useState(false);
  const [msgFeedback, setMsgFeedback]                  = useState("");
  const [msgClass, setMsgClass]                        = useState("");
  const [msgPassFeedback, setMsgPassFeedback]          = useState("");
  const [msgPassClass, setPassMsgClass]                = useState("");

  const [processing, setProcessing]                    = useState(false);
  //console.log(user);
  const profileImgRef = useRef();

  const nameRef                                = useRef();
  const surnameRef                             = useRef();
  const emailRef                               = useRef();
  const roleRef                                = useRef();
  const phonenumberRef                         = useRef();
  const bioRef                                 = useRef();
  const professionRef                          = useRef();

  const passwordRef                            = useRef();
  const confirmpasswordRef                     = useRef();

  const triggerImageSelect = () => {
    profileImgRef.current.click();
  }

  const checkProfileChange  = () => {
    if(profileImgRef.current.files[0]){
      //  console.log("Preview");
        //setImagePreview(URL.createObjectURL(mainImage.current.files[0]));
        setBtnSaveSwitch(true)
    }
  }

  const saveProfileImage  = async () => {
    try{
      setProcessing(true);
      const fdata = new FormData();   
      fdata.append("profilepic", profileImgRef.current.files[0]);
      fdata.append("identity", user._id);
      console.log(user._id);
      //console.log(user.accessToken);

      var config = {
        method: 'put',
        url: CONSTANTS.API_URL + 'pictures/profile',
        headers: { 
          'token': "Bearer " + user.accessToken,
          'Content-Type': 'multipart/form-data' 
        },
        data : fdata
      };
      const response = await axios(config);

       var localStoreImg = JSON.parse(localStorage.getItem(CONSTANTS.SESSION_COOKIE));

       localStoreImg = {
        "_id" : localStoreImg._id,
        "name" : localStoreImg.name,
        "surname" : localStoreImg.surname,
        "email" : localStoreImg.email,
        "profilePic" : response.data.updatedUser.profilePic,
        "isAdmin" : localStoreImg.isAdmin,
        "roles" : localStoreImg.roles,
        "bio" : localStoreImg.bio,
        "phonenumber" : localStoreImg.phonenumber,
        "profession" : localStoreImg.profession,
        "approve": user.approve,
        "extras" : localStoreImg.extras,
        "createdAt" : localStoreImg.createdAt,
        "updatedAt" : localStoreImg.updatedAt,
        "accessToken" : localStoreImg.accessToken,
      }
      localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(localStoreImg));
       //console.log(response.data);
       user.profilePic = response.data.updatedUser.profilePic;
       //console.log("$$$$$$$$$$$$$$$$$");
       setProcessing(false);
       window.location.reload();

    }catch(err){
      console.log(err);
      setProcessing(false);
    }
   
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  
  async function handleFormUserPasswordReset(e){
    e.preventDefault();  
    setProcessing(true);
    if(passwordRef.current.value.length > 4){
        if(passwordRef.current.value === confirmpasswordRef.current.value ){
           
          const dataObs = {
            "id": user._id,
            "email": user.email,
            "password": passwordRef.current.value
          }
          
          //console.log(dataObs);
          const response = await axios.put(CONSTANTS.API_URL+"users/passreset", dataObs, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
          });
          //console.log("XXXXXXXXXXXX")
          //console.log(response.data);
          setMsgFeedback(response.data.message);
          setPassMsgClass(response.data.alert); 
          setProcessing(false);
        }else {
          setMsgPassFeedback("Passwords are not equal");
          setPassMsgClass("alert-danger"); 
          setProcessing(false);
        }
    } else {
      setMsgPassFeedback("Password cannot be less than 4 characters");
      setPassMsgClass("alert-danger"); 
      setProcessing(false);
    }
    
  }

  async function handleFormEditUser(e) {
    e.preventDefault();  
    setProcessing(true);
        //console.log(nameRef.current.value);
        //console.log(surnameRef.current.value);
        setMsgClass("");  
        setMsgFeedback("");

        try{
          ///////////////////////
              if(nameRef.current.value || surnameRef.current.value || emailRef.current.value){
              
                if (!isValidEmail(emailRef.current.value)) {
                
                  setMsgFeedback("Email is invalid");
                  setMsgClass("alert-danger");   
                } else {
                  ////////////////////////------------>
                    let rule = false;
                
                    //console.log("Role: " + user.roles);
                    if(roleRef.current.value === "admin"){
                      rule = true;
                    }

                    const userObject = {
                        "userId": user._id,
                        "name" : nameRef.current.value,
                        "surname" : surnameRef.current.value,
                        "email" : emailRef.current.value,
                        "isAdmin" : rule,
                        "roles": roleRef.current.value,
                        "bio" : bioRef.current.value,
                        "phonenumber" : phonenumberRef.current.value,
                        "profession" : professionRef.current.value,
                        "approve": user.approve,
                        "extras": user.extras
                      }

                      const response = await axios.put(CONSTANTS.API_URL+"users/updateuser", userObject, {
                        headers: {
                            token: "Bearer "+ user.accessToken
                        }
                      });
                 
                      //console.log(response.data);
                      //console.log("Local Storage:");
                      var localStore = JSON.parse(localStorage.getItem(CONSTANTS.SESSION_COOKIE));
                      //console.log(localStore);
                      localStore = {
                        "_id" : localStore._id,
                        "name" : response.data.name,
                        "surname" : response.data.surname,
                        "email" : response.data.email,
                        "profilePic" : response.data.profilePic,
                        "isAdmin" : response.data.isAdmin,
                        "roles" : response.data.roles,
                        "bio" : response.data.bio,
                        "phonenumber" : response.data.phonenumber,
                        "profession" : response.data.profession,
                        "approve": user.approve,
                        "extras": user.extras,
                        "createdAt" : localStore.createdAt,
                        "updatedAt" : response.data.updatedAt,
                        "accessToken" : localStore.accessToken,
                      }
                      localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(localStore));
                      setMsgFeedback("Edit was successful.");
                      setMsgClass("alert-success"); 
                      //console.log("vvvhhhhmmmmm vvvhhhhmmmmm vvvhhhhmmmmm")
                  ///////////////////////-------------->
                }
                
              //console.log("We are goo to "); 
              setProcessing(false);   
            }else {
              setMsgFeedback("Please fill in all required fields");
              setMsgClass("alert-danger"); 
              setProcessing(false);   
            }
          //////////////////////
        }catch(erEddit){
          console.log(erEddit);
          console.log("MESSS");
        }
  }

  return (
    <div className="main-standard">
      <div className="flexme">
        <div className="card no-pad">
            <div className="card-body">
                 <div className="img-box pd25">
                      <div className="image-profile-container large-profile">
                            {(
                              user.profilePic === '-' ? <div className='dsh-profile lg-profe' style={{ 
                                    backgroundImage: `url(${CONSTANTS.IMAGE_PROFILE})` 
                                  }}></div> : <div className="dsh-profile lg-profe-ds" style={{
                                    backgroundImage: `url(${user.profilePic})` 
                                  }}></div>
                              )
                            }
                        </div>
                        <input type="file"  className="ops-profile" ref={profileImgRef} onChange={() => checkProfileChange()} accept="image/*" required/>
                        <button className="no-button-style btn-circle-st"><div className="inicons" onClick={triggerImageSelect}><FontAwesomeIcon icon={faUpload} /></div></button>
                        {btnSaveSwitch && (
                          <div className="height-item">
                            <button className="btn btn-picky" onClick={saveProfileImage}>SAVE IMAGE</button>
                          </div>
                        )}
                 </div>

                  <div className="pass-reset">
                    <div className="sec-info-head pd25">
                        <h2>Password Reset</h2>
                    </div> 
                    <div className="pass-reset-content pd25">
                           <form onSubmit={handleFormUserPasswordReset}>
                                               
                                <div className="form-group pd10">
                                    <div className="lbldesc">Password:</div>
                                    <input type="password" className="form-control wide100" ref={passwordRef} />
                                </div>
                                <div className="form-group pd10">
                                    <div className="lbldesc">Confirm Password:</div>                             
                                    <input type="password" className="form-control wide100" ref={confirmpasswordRef} />
                                </div>
                                <div className="form-group pd10">
                                   <button type="submit" className="btn btn-picky">Send</button>
                                </div>                  

                           </form>

                           <div className="msfeed">
                                {
                                  msgPassFeedback && (
                                    <div className={"alert-area " + msgPassClass}>
                                        {msgPassFeedback}
                                    </div>  
                                  )
                                }
                          </div>
                    </div>
                  </div>
            </div>
        </div>
        <div className="card right70perc no-pad">
            <div className="card-body">
               <div className="sec-info-head pd25">
                   <h2>Edit Profile</h2>
               </div>
               <div className="cont-form pd25">
                  <form onSubmit={handleFormEditUser}>
                      <div className="flexme form-flexer">
                          <div className="form-group wide50 pd10">
                              <div className="lbldesc">Name*:</div>
                              <input type="text" className="form-control wide100" ref={nameRef} defaultValue={user.name} required/>
                          </div>
                          <div className="form-group wide50 pd10">
                              <div className="lbldesc">Surame*:</div>
                              <input type="text" className="form-control wide100" ref={surnameRef} defaultValue={user.surname} required/>
                          </div>
                      </div>
                      <div className="flexme form-flexer">
                          <div className="form-group wide50 pd10">
                              <div className="lbldesc">Email*:</div>
                              <input type="text" className="form-control wide100" ref={emailRef} defaultValue={user.email} required/>
                          </div>
                          <div className="form-group wide50 pd10">
                              <div className="lbldesc">Profession:</div>
                              <input type="text" className="form-control wide100" ref={professionRef} defaultValue={user.profession}/>
                          </div>
                         
                      </div>
                      <div className="flexme form-flexer">                          
                          <div className="form-group wide50 pd10">
                              <div className="lbldesc">Phone Number:</div>
                              <input type="text" className="form-control wide100" ref={phonenumberRef} defaultValue={user.phonenumber}/>
                          </div>
                          <div className="form-group wide50 pd10">
                              <div className="lbldesc">Role:</div>
                             
                              <input type="text" className="form-control wide100" ref={roleRef} defaultValue={user.roles} readOnly/>
                          </div>
                      </div>
                      <div className="full-width">
                         <div className="form-group pd10">
                             <div className="lbldesc">Bio:</div>
                             <textarea className="form-control wide100 minh90" ref={bioRef} defaultValue={user.bio}></textarea>
                         </div>
                      </div>
                      <div className="full-width">
                         <div className="form-group pd10">
                            <button type="submit" className="btn btn-picky">Send</button>
                         </div>
                      </div>
                  </form>

                       <div className="msfeed">
                                {
                                  msgFeedback && (
                                    <div className={"alert-area " + msgClass}>
                                        {msgFeedback}
                                    </div>  
                                  )
                                }
                          </div>
                          {
                              processing && (
                                  <div className="img-source">
                                  <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                                  </div>
                              )
                          }
                </div>     
            </div>
        
        </div>
      </div>
    </div>
  )
}

export default Profile
import React, { useRef, useState } from 'react'
import './topnavbar.css';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faHouseUser, faAddressCard, faArrowRightFromBracket , faBolt} from '@fortawesome/free-solid-svg-icons';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { useSelector } from 'react-redux';

function Tobnavbar() {

  const {user}                                                               = useSelector((state) => state.auth);

  const [processing, setProcessing]             = useState(false);
  const [msgNeutral ,setMsgNeutral]             = useState("");
  const [msgNeutralClass, setMsgNeutralClass]   = useState("");
  
  const supplierCodeRef                   = useRef();
  const userCatCodeRef                    = useRef();
  const navigate                          = useNavigate();

  const perfomLogOut = () =>{
      //console.log("Logout");
      localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
      document.location.href="/login";
  }

  const sendYourSelfTheCode = async () => {
    setProcessing(true);
      const code = Math.floor((Math.random() * 100000) + 1)
  
      const codeApprove = {
        "email" : user.email,
        "codereset": code
      }
      setMsgNeutral("");
      setMsgNeutralClass("");
      const saveUserCode = await axios.post(CONSTANTS.API_URL +"users/verifystandard", codeApprove,{
        headers: {
            token: "Bearer "+ user.accessToken,
            'Content-Type': 'application/json'
        }
       });

       if(saveUserCode.status === 200){
        const getemailFeedback = await axios.get("https://api.picnick.co.za/emailing/email.php?email_test=945grhpicnickC&eemail=" +  user.email + "&email_code_reset=" + code);
   
          if(getemailFeedback.status === 200){
            setMsgNeutral("Your approval code has been emailed to you. ");
            setMsgNeutralClass("alert-secondary");
          }else{
            setMsgNeutral("Technical difficulty. Please try again ");
            setMsgNeutralClass("alert-danger");
          }
       }else{
          setMsgNeutral("Something went wrong. Please try again ");
          setMsgNeutralClass("alert-danger");
       }
       setProcessing(false);
  }
  const approveStandardccount = async () => {
    if(userCatCodeRef.current.value.length === 5){
      setProcessing(true);
      setMsgNeutral("");
      setMsgNeutralClass("");
      try{
       
        var config = {
          method: 'get',
          url: CONSTANTS.API_URL +'users/vericheckstandard?myemail=' + user.email + "&userid="+user._id+"&code=" + userCatCodeRef.current.value,
          headers: { 
            'token': 'Bearer '+ user.accessToken, 
            'Content-Type': 'application/json'
          }
        };

        const checkUserCode = await axios(config);

         if(checkUserCode.status === 200){
          
          if(checkUserCode.data.alert === "success"){
          
            //add location
            var localStoreImg = JSON.parse(localStorage.getItem(CONSTANTS.SESSION_COOKIE));

              localStoreImg = {
                "_id" : localStoreImg._id,
                "name" : localStoreImg.name,
                "surname" : localStoreImg.surname,
                "email" : localStoreImg.email,
                "profilePic" : localStoreImg.profilePic,
                "isAdmin" : localStoreImg.isAdmin,
                "roles" : localStoreImg.roles,
                "bio" : localStoreImg.bio,
                "phonenumber" : localStoreImg.phonenumber,
                "profession" : localStoreImg.profession,
                "approve": checkUserCode.data.updatedUser.approve,
                "extras" : localStoreImg.extras,
                "createdAt" : localStoreImg.createdAt,
                "updatedAt" : localStoreImg.updatedAt,
                "accessToken" : localStoreImg.accessToken,
              }
              localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(localStoreImg));
              setMsgNeutral(checkUserCode.data.message);
              setMsgNeutralClass("alert-success");
            //refresh
            setTimeout(function() {
              window.location.reload("");
            }            , 2000);
          }else{
            setMsgNeutral(checkUserCode.data.message);
            setMsgNeutralClass("alert-danger");
          }
         }else {
          setMsgNeutral("Something went wrong, Please try again later.");
          setMsgNeutralClass("alert-danger");
         }
      }catch(err){
        console.log(err);
      }
      setProcessing(false);
    }else {
      setMsgNeutral("Code is only 5 characters long.");
      setMsgNeutralClass("alert-danger");
    }
  }
  const approveThisAccount = async () => {
   
    if(supplierCodeRef.current.value.length > 3){
          try{
            setMsgNeutral("");
            setMsgNeutralClass("");
              const userAcc = {
                "userid" : user._id,
                "supplierid": user.extras[0].supplierId,
                "suppliernm": user.extras[0].supplierName,
                "code":supplierCodeRef.current.value
              }
              
            console.log(userAcc);
             const approveData = await axios.put(CONSTANTS.API_URL +"suppliers/verifyapprove", userAcc, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
             })

             //console.log(approveData);
             //console.log(approveData.data);
             if(approveData.data.status === "401"){
              setMsgNeutral(approveData.data.message);
              setMsgNeutralClass("alert-danger");
             }else {
              
              setMsgNeutral("")                  
              setMsgNeutralClass("");
                if(approveData.data.updateUser.approve){
                  user.approve = true;
                  localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(user));
                  navigate("/", { replace: true });
                }else {
                  console.log("Inner Problem.");
                  setMsgNeutral("Something went wrong. Please try again later.")                  
                  setMsgNeutralClass("alert-danger");
                }
                
             }
          }catch(erCode){
            console.log(erCode);
            console.log("Errror Approve Account");
            setMsgNeutral("Something went wrong. Please try again later.")                  
            setMsgNeutralClass("alert-danger");
          }
    }else {
      setMsgNeutral("Code is incomplete.")              
      setMsgNeutralClass("alert-danger");
    }
  }
  return (
    <div className="nav-bar">
        <div className="src-item"></div>
        <div className="content-nav">
          {
            /*            <span className="sp-item"> <Link to="/settings" className="link-nav-item-top"><FontAwesomeIcon icon={faGear} /></Link> </span>
            */}
            <Dropdown>
              <Dropdown.Toggle variant="success profile-ps" id="dropdown-basic">
                <div className="image-profile-container">
                  {(user.profilePic === '-' 
                      ? <div className='dsh-profile' style={{ 
                          backgroundImage: `url(${CONSTANTS.IMAGE_PROFILE})` 
                        }}></div> 
                      : 
                        <div className="dsh-profile" style={{
                          backgroundImage: `url(${user.profilePic})` 
                        }}>

                        </div>)}
                </div>
              </Dropdown.Toggle>

            <Dropdown.Menu>
              {(user.profilePic !== '-' ) && <div className="dash-large-profile" style={{backgroundImage: `url(${user.profilePic})` }}></div>}
              <div className="title-dash-name">
                    {user.name}  {user.surname} 
              </div>
              <div className="title-dash-pro">
                    {user.profession}  
              </div>
              <div className="list-nav-item pdspace">
              <Dropdown.Item href="/profile"><span className="navicon"><FontAwesomeIcon icon={faAddressCard} /></span> <span className="nav-i-text">Profile</span></Dropdown.Item>
              <Dropdown.Item href="/"><span className="navicon"><FontAwesomeIcon icon={faHouseUser} /></span> <span className="nav-i-text">Home</span></Dropdown.Item>
              <Dropdown.Item href="/knowledge-base"><span className="navicon"><FontAwesomeIcon icon={faBolt} /></span> <span className="nav-i-text">Knowledgebase</span></Dropdown.Item>
              </div>
              <div className="log-box">
                <button onClick={perfomLogOut}  className="btn btn-logout"><span><FontAwesomeIcon icon={faArrowRightFromBracket}/></span> <span className="bg-logout-text">Log out</span></button>
              </div>
            </Dropdown.Menu>
            
          </Dropdown>
            
        </div>
        {
        /*
        If the user is not approved then they neeed to get a key from the supplier or catelogue host then from there they can approve themselves
        otherwise thte supplier or catelogue person will approve them. 
        */
        (!user.isAdmin && !user.approve ) && (
          <div className="no-approve-outer">
            <div className="not-approved-box">
              <h2 className="text-center">You are not yet approved</h2>
               <div className="conte-approve">
                {
                  (user.roles === "standard") ?
                     <>
                          <div className="">
                            <div className="form-group">
                                   <div className="lbldesc">User Approval Code*: </div>
                                    <input type="text" className="form-control" ref={userCatCodeRef} placeholder="Enter Approval Code" />
                                    <div className="form-group pdtpbt10">
                                      <button className="btn btn-picky mgright10" onClick={() => sendYourSelfTheCode()}  disabled={processing}>Send Yourself the code</button>
                                      <button className="btn btn-picky" onClick={() => approveStandardccount()} disabled={processing}>Approve Account</button>
                                  </div>
                            </div>
                          </div>
                     </>
                  :
                  <>
                    {
                       user.extras[0].supplierName && (

                        <div className="">
                            <div className="form-group">
                                <p>{user.extras[0].supplierName}</p>
                                <div className="lbldesc">Supplier Approval Code*: </div>
                              <input type="text" className="form-control" ref={supplierCodeRef} placeholder="Enter Approval Code" />
                              <p>You can request that a registered supplier gives you an approval code to enter in.</p>
                            </div>
                            <div className="form-group pdtpbt10">
                                <button className="btn btn-picky" onClick={() => approveThisAccount()}>Approve Account</button>
                            </div>
                           
                      </div>
                      )
                    }
                  </>
                }
               
               
               <p> You could contact us to request for your account to be approved. <a href="traffic@cjmarketing.co">traffic@cjmarketing</a></p>
               {
                              processing && (
                                <div className="processings">
                                   <img src={CONSTANTS.SMALL_PROCESSING} className="small-pr" />
                                </div>
                              )
                            }

                           {
                              msgNeutral && (
                                <div className={"alert " + msgNeutralClass}>
                                  {msgNeutral}
                                </div>
                              )
                            }
                  <div className="log-box-mini">
                    <button onClick={perfomLogOut}  className="btn btn-logout"><span><FontAwesomeIcon icon={faArrowRightFromBracket}/></span> <span className="bg-logout-text">Log out</span></button>
                  </div>
               </div>
            </div>
          </div>
        )
        }
    </div>
  )
}

export default Tobnavbar
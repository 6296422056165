import React, { useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Button } from 'primereact/button';

function TBVPictoTradeUpdates({person}) {

    const [processing, setProcessing]                                 = useState(false);

    const [tokenTradeCatalogues, setTokenTradeCatalogues]             = useState();
    const [selectedTokenCatal, setSelectedTokenCatalogue]             = useState();
    const [progressOn, setProgressOn]                                 = useState(false);
    const [progressAmount, setProgressAmount]                         = useState(0);
   
    const [pictureCollection, setPictureCollection]                   = useState([]);
    const [totalPics, setTotalPics]                                   = useState();
    const [msgProcessText, setMsgProcessText]                         = useState();
    const [style, setStyleAmount]                                     = useState({
        opacity: 1,
        width: `${progressAmount}%`
    });

    const [messgBackErr, setMessgBackErr]                             = useState("");

    const tradeListRef                          = useRef();
    const currenURLForUpdatingRef               = useRef();

    useEffect(() => {
        collectTokenTradeCatalogues();
    },[])


    const collectTokenTradeCatalogues = async () => {
        try{
            
            const response = await axios.get(CONSTANTS.API_URL+"catalogues/list/tokentrade/catalogs");

            setTokenTradeCatalogues(response.data);

        }catch(err){
            console.log("TBVPictoTradeUpdates");
            console.log(err);        
               
        }
    }

    const getPictureCollection = async () => {
        setProcessing(true);
        try{

           ////////////

           const res = await axios.get(CONSTANTS.API_URL + "catalogues/details/" + tradeListRef.current.value, {
            headers: {
              token: "Bearer " + person.accessToken
            }
          });
          
          setSelectedTokenCatalogue(res.data[0]);
          
            setPictureCollection(res.data[0].pics);
            setTotalPics(res.data[0].pics.length);
          
            setProcessing(false);
        }catch(err){
            setProcessing(false);
        }
    }

    const processTransferToTrade = async () => {
        console.log("Touch Lets go");

        try{
            
            const getURL = currenURLForUpdatingRef.current.value;

            if(getURL.length > 6){
                setProgressOn(true);
                var count = 0;
                
                for (const resultPic of pictureCollection) {                    

                        let dataProgToken = JSON.stringify({
                            "barcode": resultPic.barcode,
                            "product": {
                                "sub_title": resultPic.title,
                                "image_url" : resultPic.imgurl
                            }
                          });

                        count++;
                        //await new Promise((resolve) => setTimeout(resolve, 100));
                        let config = {
                            method: 'put',
                            maxBodyLength: Infinity,
                            url: getURL,
                            headers: { 
                              'Authorization': 'Bearer '+ selectedTokenCatal.tokentrade, 
                              'Content-Type': 'application/json'
                            },
                            data : dataProgToken
                          };

                        const res = await axios.request(config);
               
                        setProgressAmount(Math.round((count / totalPics) * 100));
                        setStyleAmount({
                          opacity: 1,
                          width: `${Math.round((count / totalPics) * 100)}%`
                        });
                        
                        setMsgProcessText(resultPic.title + " - " + resultPic.barcode);
                }
                ////LOOP END
                setMsgProcessText("Update is complete " + count);
            }else {
                setMessgBackErr("Please Add appropriate URL from Letstrade");
            }
        }catch(err){
            console.log(err);
            setMessgBackErr("Something went wrong, please try again.");
        }
       

    }
    /*
    const getCatalogue = async () => {
        setProcessing(true);
        try { 
    
          
    
          setCatalogue(res.data[0]);
          setPictures(res.data[0].pics);
          setTotalPics(res.data[0].pics.length);
          setMemberIds(res.data[0].userIds);
          setProcessing(false);
        } catch (err) {
          console.log(err);
          setProcessing(false);
         
        }
    }
    */
  return (
    <div className="tv-box">
        <h3>Letstrade Updater</h3>
        <div className="base copy">
             <h5>Select the Catalogue</h5> 
             {
                tokenTradeCatalogues && (
                  <div className="select-pict-totrade">
                     <div className="form-group">
                            <select className="form-control" ref={tradeListRef}>
                            {
                                tokenTradeCatalogues.map((catItem, index) => {
                                    return (
                                        <option value={catItem._id} key={index}>{catItem.title}</option>
                                    )
                                })
                            }
                        </select>
                      </div>
                      <div className="form-group">
                            <Button className="btn btn-picky" label="Commence Update Token" onClick={getPictureCollection} />
                      </div>
                      {
                        totalPics && (
                            <div className="total-pics mgtop10">
                                <h2>Total pictures in this catalogue: {totalPics}</h2>
                                <div className="form-group">
                                    Enter Url Link
                                    <input type="text"  ref={currenURLForUpdatingRef} className="form-control" />
                                </div>
                                <div className="form-group">
                                <Button className="btn btn-picky" label="Process transfer" onClick={processTransferToTrade} />
                                </div>
                                <div className="cot mgtop10">
                                    {
                                        messgBackErr && (
                                            <div className="alert alert-danger">
                                                {messgBackErr}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                      }
                      <div className="mgtop10">
                           {
                            progressOn && (
                                <div className="flexme">
                                    <div className="progress">
                                        <div className="progress-done" style={style}>
                                            {progressAmount}%
                                        </div>
                                    </div>
                                    <div className="msg-processing-item">
                                        {msgProcessText}
                                    </div>
                                </div>
                            )
                           }
                        </div>
                  </div>
                )
             }
        </div>

           {
            processing && (
                <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                </div>
            )
        }
    </div>
  )
}

export default TBVPictoTradeUpdates
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgAddTokenCat(props) {

    const [tokenString, setTokenString]     = useState("");
    const [tokenObject, setTokenObject]     = useState("");
    useEffect(()=> {
     
        fetchCurrentToken();
         
    },[])

    const fetchCurrentToken = async () => {
        //console.log("######################");
        //console.log(props.catid);
      
        try{
            /*
            var catData = JSON.stringify({
                "catalogueId": props.catid
              });
              */
             var catString = props.catid
              //console.log(catString);
           
            var config = {
                method: 'get',
                url: CONSTANTS.API_URL + 'tokens/catalogue/'+ catString,
                headers: { 
                  'token': 'Bearer '+ props.person.accessToken, 
                  'Content-Type': 'application/json'
                }
              };

            const resToken = await axios(config);
       
            //console.log("token Message")
            // console.log(resToken.data);
             if(resToken.data.tokensObs[0]){
                setTokenObject(resToken.data.tokensObs[0]);
                setTokenString(resToken.data.tokensObs[0].token)
             }
            
        }catch(erToken){
            console.log(erToken);
            console.log("Error Fetching Token ");
        }
    }
    const handleCreateTokenUpdate = async () => {
        //console.log("Cata " + props.catid);
        //console.log("Tok ID " + tokenObject._id);
       
        try{
           
            const res = await axios.put(CONSTANTS.API_URL +"tokens/tokenupdate", {
                tokenId: tokenObject._id
            }, {
                headers: {
                    'token': 'Bearer '+ props.person.accessToken, 
                }
            });

            //console.log(res);
            //console.log(res.data);
            //props.setDisplayTokenDialog(false);
            setTokenObject(res.data.updatedToken);
            setTokenString(res.data.updatedToken.token)
        }
        catch(errors){
            console.log("Create TOKEN ERROR");
            console.log(errors)
        }
      
    }
    const handleCreateToken = async () => {
        //console.log("Cata " + props.catid);
        //console.log("Tok ID " + tokenObject._id);
       
        try{
           
            const res = await axios.post(CONSTANTS.API_URL +"tokens", {
                catalogueId: props.catid
            }, {
                headers: {
                    'token': 'Bearer '+ props.person.accessToken, 
                }
            });
          
            //console.log(res);
           // console.log(res.data);
            //props.setDisplayTokenDialog(false);
            setTokenObject(res.data);
            setTokenString(res.data.token)
        }
        catch(errors){
            console.log("Create TOKEN ERROR");
            console.log(errors)
        }
      
    }

  return (
        <Dialog header="Catalogue Token" visible={props.dspCreateCatTokenDialog} style={{ width: '50vw' }} modal={true} onHide={() => props.setDspCreateCatTokenDialog(false)}
        footer={
            <div>
                {
                    tokenString ?
                    <Button className="btn btn-picky" label="Create New Token" onClick={handleCreateTokenUpdate} />
                    :
                    <Button className="btn btn-picky" label="Create Token" onClick={handleCreateToken} />
                }
                
                <Button className="btn btn-picky" label="Close" onClick={() => props.setDspCreateCatTokenDialog(false)} />
            </div>
        }>
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-12">
             
               <div className="tok-info">
                To make requests to your catalogue data please use the following Token.

                {
                    tokenString && (
                        <p className="token-display">{tokenString}</p>
                    )
                }
               </div>
            </div>
        </div>


    </Dialog>
  )
}

export default DlgAddTokenCat
import React, { useRef, useState  } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

function DlgUserAdd({showhowDialAddUser, setShowDialAddUser, newUser, setNewUser, supplierItems, personAdmin}) {
    const [processing, setProcessing]                            = useState(false);

    const [msgFeedback, setMsgFeedback]                          = useState("");
    const [msgClass, setMsgClass]                                = useState("");

    const [supSelected, setSupSelected]                          = useState(false);
    const [passDisplay, setPassDisplay]                          = useState("");

    const [supId, setSupId]                                      = useState("");
    const [supName, setSupName]                                  = useState("");

    const nameRef                                = useRef();
    const surnameRef                             = useRef();
    const emailRef                               = useRef();
    const roleRef                                = useRef();
    const supplierRef                            = useRef();
    const phonenumberRef                         = useRef();
    const bioRef                                 = useRef();
    const professionRef                          = useRef();

    const checkWhichSelection = () => {
        console.log("Die for");
        console.log(roleRef.current.value);
    
        if(roleRef.current.value === "supplier"){
          setSupSelected(true);
        }else {
          setSupSelected(false);
        }
    }

    async function handleFormCreateUser(e) {
        e.preventDefault();  
        setProcessing(true);   
  
        setMsgFeedback("");
        setMsgClass("");
  
        try{
          /*
          console.log(nameRef.current.value);
          console.log(surnameRef.current.value);
          console.log(roleRef.current.value);
          */
          let rule = false;
          let extras = [];
          let supId = "";
          let supName  = "";

          if(supSelected){
              supId    = supplierRef.current.value;
              supName  = supplierRef.current.options[supplierRef.current.selectedIndex].text;

              let vrSupplier = {
                "supplierId" : supId,
                "supplierName" : supName,
              }

            extras.push(vrSupplier);            
            ///////////////////////////////////
          }
         
          if(roleRef.current.value === "admin"){
            rule = true;
          }
          const password = makeid(7);
          /*
          console.log(password); 
          console.log(extras);          
          */
          //console.log("Console ###### Console");
          
          const userContent = {
            "name" : nameRef.current.value,
            "surname" : surnameRef.current.value,
            "email" : emailRef.current.value,
            "password" : password,
            "isAdmin" : rule,
            "profilePic" : "-",
            "roles" : roleRef.current.value,
            "bio" : bioRef.current.value,
            "phonenumber" : phonenumberRef.current.value,
            "profession" : professionRef.current.value,
            "extras" : extras,
            "approve" : true
           }
           
           const responseReg = await axios.post(CONSTANTS.API_URL+"auth/register", userContent);
           //console.log(responseReg.data);
         
            //console.log(formData);
            setProcessing(false);
            setPassDisplay(password);
            //once the user has been created then add that user's id to the supplier id 
            if(supSelected){
              //since the is a supplier then lets go add them
                    let vrUserSupplier = {
                      "id": responseReg.data._id,
                      "supid" : supId,
                      "supname" : supName,
                    }
                    var dataBox = JSON.stringify(vrUserSupplier);

                   // console.log(dataBox);

                    var configBox = {
                      method: 'put',
                      url: CONSTANTS.API_URL + 'auth/addusertosup',
                      headers: { 
                        'Authorization': 'Bearer ' + personAdmin.accessToken, 
                        'Content-Type': 'application/json'
                      },
                      data : dataBox
                    };
             
                    const atUpad = await axios(configBox);
                    //console.log(atUpad.data);
                   
            }
            setMsgFeedback("Thank you the User has been created. Password: "+ password);
            sendEmailPassword(emailRef.current.value, password);
            setNewUser(newUser + 1);
            setMsgClass("alert-success");
            resetFields();
           
           // e.target.reset();
        } catch (err){
          console.log("Something went wrong ");
          setMsgFeedback("Something went wrong please try again later.");
          setMsgClass("alert-danger");
          console.log(err);
          setProcessing(false);
       
        }       
    }
  
    const resetFields = () => {
        nameRef.current.value     = "";
        surnameRef.current.value  = "";
        emailRef.current.value    = "";
        phonenumberRef.current.value = "";  
        bioRef.current.value    = "";
        professionRef.current.value = "";  
        
    }
    const sendEmailPassword = async (theEmail, thePass) => {
        //onsole.log("SEnd email " + theEmail + " - " + thePass);
  
        try{
          const response = await axios.get("https://api.picnick.co.za/emailing/email.php?email_test=picnickCutterX&eemail=" + theEmail + "&epass=" + thePass);
          console.log(response.data);
        }catch(err){
          console.log("Ready or Not");
          console.log(err)
        }
    }

    function makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() *  charactersLength));
      }
      return result;
    }

  return (
   <div className="block-comp">
        <Dialog header="User Details"  visible={showhowDialAddUser} onHide={() => setShowDialAddUser(false)} style={{ width: '50vw' }}
            footer={
            <div>
                {/* link button */}
                <Button className="btn btn-picky" label="Close" onClick={() => setShowDialAddUser(false)} />
            </div>
            }>
                <div className="modal-body-content">
                <div className="user-form">
                              <form onSubmit={handleFormCreateUser}>
                                <div className="form-group">
                                      <div className="lbldesc">Name:</div>
                                      <input type="text" className="form-control wide100" ref={nameRef} required/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Surname:</div>
                                      <input type="text" className="form-control wide100" ref={surnameRef} required/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Email:</div>
                                      <input type="email" className="form-control wide100" ref={emailRef} required/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Roles:</div>
                                      <select ref={roleRef} className="form-control wide100" onChange={() => checkWhichSelection()}>
                                        <option value="standard">Standard</option>
                                        <option value="supplier">Supplier</option>
                                        <option value="admin">Admin</option>
                                      </select>
                                </div>
                                {
                                  supSelected && (
                                    <div className="form-group">
                                        <div className="lbldesc">Supplier:</div>
                                        <select ref={supplierRef} className="form-control wide100">
                                          {supplierItems.map((suppitem) => {
                                            return (
                                              <option value={suppitem._id}>{suppitem.title}</option>
                                            )
                                          })}
                                        </select>
                                  </div>
                                  )
                                }
                                <div className="form-group">
                                      <div className="lbldesc">Phone Number:</div>
                                      <input type="text" className="form-control wide100" ref={phonenumberRef} />
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Profession:</div>
                                      <input type="text" className="form-control wide100" ref={professionRef} />
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Bio:</div>
                                      <textarea className="form-control wide100 heightf150" ref={bioRef} ></textarea>                                     
                                </div>
                                <div className="form-group">
                                     <button type="submit" className="btn btn-picky" disabled={processing}>Create</button>                             
                                </div>
                              </form>

                          </div>
                          <div className="">
                                {
                                  msgFeedback && (
                                    <div className={"alert-area " + msgClass}>
                                        {msgFeedback}
                                    </div>  
                                  )
                                }
                          </div>
                {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                    }
                </div>
        </Dialog>
    </div>
  )
}

export default DlgUserAdd
import React, { useContext } from 'react'
import CUSSupplierItems from '../components/Home/CUSSupplierItems';
import CUSCatalogueItems from '../components/Home/CUSCatalogueItems';
import TotalCatalogues from '../components/Home/TotalCatalogues';
import TotalPictures from '../components/Home/TotalPictures';
import TotalSuppliers from '../components/Home/TotalSuppliers';
import TotalUnAssignedPics from '../components/Home/TotalUnAssignedPics';
import CUSSupplierUsers from '../components/Home/CUSSupplierUsers';
import TotalCategories from '../components/Home/TotalCategories';
import NoSupplierPics from '../components/Home/NoSupplierPics';
import { useSelector } from 'react-redux';

function Home() {
   const {user}                                                = useSelector((state) => state.auth);
    
   //console.log(user);
   //console.log("Thata");
  return (
    <div className="main-standard">
           <h2 className="lbl-title-page">Home</h2>
           
        <div className="ct-section mobOff">
           <div className="row rw-low">
            {
               user.isAdmin ?
               <>
                     <TotalPictures />
                     <NoSupplierPics />
                     <TotalSuppliers />
                     <TotalCategories />
                     <TotalCatalogues />
                     <TotalUnAssignedPics />                    
                     
               </>
               : 
               (
                  user.roles === "supplier"
                    ?
                  <>
                     <CUSSupplierItems user={user} />
                     <CUSSupplierUsers user={user} />
                  </>
                  :
                  <>
                     <CUSCatalogueItems user={user} />
                  </>
               )
            }


           </div>
        </div>
    </div>
  )
}

export default Home
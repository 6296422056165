import React, {useState , useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";


function DlgAddPipelineItem(props) {

    const pipeTitleRef                      = useRef();
    
    const [msg , setMessage]                = useState("");
    const [msgAlert , setMessageAlert]      = useState("");

    const handleSubmitPipeItem = async (e) => {
        e.preventDefault();
        try{
            console.log("");
           
              if(pipeTitleRef.current.value.length > 1){
                const pipWork = {
                    "title": pipeTitleRef.current.value,
                    "catalogue": "",
                    "catalogueid" : ""
                  }

                  var config = {
                    method: 'post',
                    url: CONSTANTS.API_URL + "catalogues/pipes",
                    headers: { 
                        token: "Bearer "+ props.person.accessToken
                    },
                    data:pipWork
                  };
                  const res = await axios(config);
                  console.log('PIPE FEED');
                  console.log(res.data)
                setMessageAlert("alert-success");
                setMessage("Pipe Item created successfully");
              }else {
                setMessageAlert("alert-danger");
                setMessage("Title needs more characters");
              }
        }catch(ers){
            console.log(ers)
            setMessageAlert("alert-danger");
            setMessage("Something went wrong");
        }
    }
  return (
    <Dialog header="Create Pipe Item" visible={props.showPipelineForm} style={{ width: '50vw' }} modal={true} onHide={() => props.setShowPipelineForm(false)}
            footer={
                <div>                 
                    <Button className="p-button-danger" label="Close" onClick={() => props.setShowPipelineForm(false)} />
                </div>
            }>
           <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <form  onSubmit={handleSubmitPipeItem}>
                        <div className="form-group">
                            <div className="lbldesc">Pipe Title*: </div>
                            <input type="text" ref={pipeTitleRef} className="form-control wfull" required/>
                        </div>
                        <div className="height-item">
                            <button className="btn btn-picky" >CREAT ITEM</button>
                        </div>
                    </form>

                    {
                        msg &&(
                            <div className={"alert " + msgAlert}>
                                 {msg}
                            </div>
                        )
                    }
                </div>
            </div>
    </Dialog>
  )
}

export default DlgAddPipelineItem
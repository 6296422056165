import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import './settings.css';
import TBVPictures from '../components/TBVPictures';
import TBVCatalogue from '../components/TBVCatalogue';
import TBVFormGal from '../components/TBVFormGal';
import TBVSetPresentation from '../components/TBVSetPresentation';
//import TBVSetPictoTrade from '../components/TBVSetPictoTrade';
import TBVImportExcel from '../components/TBVImportExcel';
import TBVAckerCSVCats from '../components/TBVAckerCSVCats';
import TBVPictoTradeUpdates from '../components/TBVPictoTradeUpdates';
import { useSelector } from 'react-redux';

function Settings() {
    const [activeIndex, setActiveIndex]                       = useState(1);
    
    const {user}                                              = useSelector((state) => state.auth);
    
    //functions
    const toggleSelectedTab = (index) => {
        setActiveIndex(index);
    }   

  return (
    <div className="main-standard">
           <h2 className="lbl-title-page">Settings</h2>
           <div className="tab-dashboard-item">
                <div className="side-dash-settings-nav">
                   
                    <div  className={activeIndex === 1 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleSelectedTab(1)}>
                         Presentation
                    </div>
                    <div  className={activeIndex === 2 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleSelectedTab(2)}>
                        Ackermans CSV  Pulls
                    </div>
                    <div  className={activeIndex === 3 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleSelectedTab(3)}>
                        Excel Athenaem
                    </div>
                    <div  className={activeIndex === 4 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleSelectedTab(4)}>
                        Update LetsTrade Products
                    </div>
                    {
                    /*
                        <div  className={activeIndex === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleSelectedTab(3)}>
                            Key Updates
                        </div>
                    */
                    }
                </div>
                <div className="tab-content-box">  
                        <div className={activeIndex === 1 ? "content-tab content-active" : "content-tab"}>
                             <TBVSetPresentation person={user} />                           
                        </div>
                        <div
                            className={activeIndex === 2 ? "content-tab content-active" : "content-tab"}
                            >
                              <TBVAckerCSVCats person={user} />
                            {
                                /*
                                <TBVSetPictoTrade person={user} />   
                                */
                            }
                        </div>
                        <div
                            className={activeIndex === 3 ? "content-tab content-active" : "content-tab"}
                            >{
                                <TBVImportExcel person={user} />
                            }
                        </div>
                        <div className={activeIndex == 4 ? "content-tab content-active" : "content-tab"}>
                            {
                                <TBVPictoTradeUpdates person={user} />
                            }
                        </div>
                </div>
           </div>               

    </div>
  )
}

export default Settings
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';


function TBVSalesCategories({person}) {

    const [cataListm, setCataList]                               = useState([]);
    const [selectedCatalogue, setSelectedCatalogue]              = useState(null);
    const [globalFilter, setGlobalFilter]                        = useState('');
    const [msgSuc, setMsgSuc]                                    = useState("");
    const [msgErr, setMsgErr]                                    = useState('');
    const [categSearched, setCategSearched]                      = useState(false);
    const [processing, setProcessing]                            = useState(false);
    const [cateCount, setCateCount]                              = useState(0);

    useEffect(() => {
      getSalesLayerCatalogueList();
    },[]);

    const headerTable = (
        <div className="table-header flex justify-content-between">
          <h4 className="mx-0 my-1">Category ({cateCount})</h4>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Categories..." />
          </span>
        </div>
      );

    const categoryTpeTemplate = (rowData) => {
       // console.log(rowData);
        if(rowData.parentCatId === "0"){
            return <div className="rt-cat">Main</div>
        }else {
            //console.log(rowData.parentCatId);
            return <div className="rt-cat">Sub</div>
        }
       // return <img src={rowData.imgurl} alt="image" className="product-image" width={80} />;
      }
      /////////////////////////////////////////////////////////

      /////////////////////////////////////////////////////////

      const getSalesLayerCatalogueList = async () => {
        setProcessing(true);
        try{

              const resCat = await axios.get(CONSTANTS.SALES_LAYER_CAT_FTCH);
              //console.log("Diggging In");
              //console.log(resCat.data.data);
              //console.log(resCat.data.status[0]);
              setCateCount(resCat.data.data.length)
              setCataList(resCat.data.data);
              setCategSearched(true);
            setProcessing(false);
        }catch(ers){
            console.log(ers);
            console.log("RRR");
            setProcessing(false);
        }
      }

      const transferToCategories = async () => {
        setProcessing(true);
        try{
         //console.log( "Taffies" );
         //console.log(cataListm);

         let arrCats             = [];
         let arrCatsLetsTrade    = [];
         let newCats             = [];
         let newCatsLetsTrade    = [];
         let rootCat    = cataListm.filter(cat => {
          return cat.parentCatId === "0"
        });

        //console.log( "Flieght" );
        //console.log(rootCat);

        rootCat.forEach(element => {

          let subCategories = cataListm.filter(subCat => {
            return element.catId === subCat.parentCatId
          });
          console.log("Brrrr Brrrrrrr Rrrrrrrrrr ");
          //rootCat[0].children.push(subCategories);
          //element["sub_categories"] = subCategories;
          //Saleslayer category
          if(subCategories.length > 0){
            console.log(subCategories.length );
            arrCats.push(subCategories);
          }
          
        });

        //console.log( "# Liverpool #" );
        newCats = arrCats[0];
        //console.log(newCats);
        newCats.forEach(el => {

          let subSubCategories = cataListm.filter(subCat => {
            return el.catId === subCat.parentCatId
          });
          //console.log("########################################");
          //rootCat[0].children.push(subCategories);
          el["sub_categories"] = subSubCategories;
          //Saleslayer category    
          el.extra_fields = []; 
          var ltTrade = {
             "name" : el.categoryName,
             "description" : el.categoryDescription,
             "sub_categories": el.sub_categories,
             "extra_fields": el.extra_fields,
          }
          newCatsLetsTrade.push(ltTrade);

        });

        //console.log( "# ICE PACK #" );
       // console.log(newCats);
        //console.log(newCats.length);
        //console.log( "# LETS TRADE #" );
        //console.log(newCatsLetsTrade);
         
          var data = JSON.stringify(newCats);
          var config = {
            method: 'post',
            url: CONSTANTS.API_URL+"categories/streamer",
            headers: { 
              'token': "Bearer "+ person.accessToken,
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          const streamer = await axios(config);
          //console.log("###### <0> ######");
          //console.log("Stream effort");
          //console.log(streamer.data);
          if(streamer.data.status === "success"){
            setMsgSuc("Category list transferred to picnic successfully.");
          }else {
            setMsgErr("Please try again later.")
          }
          setProcessing(false);
        }catch(errStream){
          console.log(errStream);
          setProcessing(false);
          setMsgErr("Something went wrong. Please try again later.")
        }

      }


  return (
    <div className="tv-box">
          {
            (cataListm.length > 0) ? (
              <>
                 <DataTable
                    value={cataListm}
                    header={headerTable}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    responsiveLayout="scroll"
                    globalFilter={(globalFilter) ? globalFilter : ''}
                    selection={selectedCatalogue}
                    onSelectionChange={e => setSelectedCatalogue(e.value)}
                    >
                    <Column field="catId" header="ID"></Column>                    
                    <Column field="categoryName" header="Category"></Column>
                    <Column field="categoryDescription" header="Desc"></Column>
                    <Column field="categoryReference" header="Ref"></Column>
                    <Column header="Type" body={categoryTpeTemplate}></Column>
                    <Column field="parentCategoryReference" header="Parent Cat"></Column>
                </DataTable>
                
                <div className="pdtb10">
                        <Button
                                className="btn btn-circle btsm1 mr-2"
                                label="Transfer to Categories"
                                onClick={() => transferToCategories()}
                                />
                </div>
              </>
            )
            :
            (

                <div className="">
                 {
                  categSearched ? 
                      <h2>Nothing found</h2>
                      :
                      <div className="">Loading...</div>
                  }                
                </div>
            )
          }
          {
              msgErr && (
                  <div className="alert alert-danger">{msgErr}</div>
              )
          }
          {
            msgSuc && (
              <div className="alert alert-success">{msgSuc}</div>
            )
          }
          {
                processing && (
                    <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                    </div>
                )
           }
    </div>
  )
}

export default TBVSalesCategories

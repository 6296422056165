import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgAddCategory(props) {


  const [addSub, setAddSub]                   = useState(false);
  const [addTitle, setAddTitle]               = useState("");
  const [msgClass, setMsgClass]               = useState("");
  const [msgFeed, setMsgFeed]                 = useState("");
  const [proc, setProc]                       = useState(false);

  const categoryTitleRef                      = useRef();
  const categoryTextAreaRef                   = useRef();

  useEffect(() => {    
    if(props.currentCat){
      console.log("WE have a sub");
      setAddSub(true);
      setAddTitle("Add Sub Category of " + props.currentCat.categoryName)
    }else {
      setAddTitle("Add Category")
      setAddSub(false);
    }
  },[props.currentCat]);

  async function handleFormCategoryAdd(e) {
    e.preventDefault();  
    setProc(true);
    try{
   
      const d = new Date();
      let catId = d.getFullYear()+ "" + d.getMonth()+ 1 + "" +d.getHours() + Math.floor((Math.random() * 10000) + 1);
        const cateGory = {
          "catId": catId,
          "categoryReference": categoryTitleRef.current.value.replace(" ", "_"),
          "categoryName":categoryTitleRef.current.value,
          "categoryDescription": categoryTextAreaRef.current.value,
          "parentCatId": "0",
          "parentCategoryReference": "",
          "sub_categories": [],
          "extra_fields": [],
          "slayer": false,
      }
      console.log(addSub);
      //????
      if(addSub){
        
        console.log("Try to add sub category");
         const cateSub = {
              "catId": catId,
              "categoryReference": categoryTitleRef.current.value.replace(" ", "_"),
              "categoryName":categoryTitleRef.current.value,
              "categoryDescription": categoryTextAreaRef.current.value,
              "parentCatId": props.currentCat.catId,
              "parentCategoryReference": props.currentCat.categoryName.replace(" ", "_"),
              "slayer": false,
          }
          const repSUBCategory = await axios.put(CONSTANTS.API_URL+"categories/createsub", cateSub, {
                headers: {
                    token: "Bearer "+ props.person.accessToken
                }
          });

          
          console.log(repSUBCategory.data);
          setProc(false);
          setMsgClass("alert-success")
          setMsgFeed("Sub Category added Succefully");
          categoryTitleRef.current.value = "";
          categoryTextAreaRef.current.value = "";
      }else {
       //????  
       //LETSTRADE1
        const repCategory = await axios.post(CONSTANTS.API_URL+"categories/", cateGory, {
            headers: {
                token: "Bearer "+ props.person.accessToken
            }
        });

        var letsTradeCat = {
          "name": categoryTitleRef.current.value,
          "description": categoryTextAreaRef.current.value,
          "image_url": "",
        }
          
        setMsgClass("alert-success");
        setMsgFeed("Category added Succefully");
        console.log(repCategory.data);
        categoryTitleRef.current.value = "";
        categoryTextAreaRef.current.value = "";
        /////=== LETS TRADE
        const repCateTrade = await axios.post(CONSTANTS.LETSTRADE1+"product/add_category", letsTradeCat, {
            headers: {
              Authorization: CONSTANTS.LTTOKEN
            }
        });
        console.log("LETS TRADE");
        console.log(repCateTrade);
        console.log(repCateTrade.data);
        setProc(false);
      }
  
    }catch(erForm){
      setProc(false);
      console.log(erForm)
      setMsgClass("alert-danger")
      setMsgFeed("Something went wrong, Please try again later");
    }
  }
  return (
    <Dialog header={addTitle} visible={props.dsplyCategoryDialog} style={{ width: '50vw' }} modal={true} onHide={() => props.setDsplyCategoryDialog(false)}
    footer={
        <div>
            <Button className="btn btn-picky" label="Close" onClick={() => props.setDsplyCategoryDialog(false)} />
        </div>
    }>
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-12">
              <div className="">
                {msgFeed && (
                  <div className={"alert " + msgClass}>
                    {msgFeed}
                  </div>
                )}
              </div>
                <form  onSubmit={handleFormCategoryAdd}>
                     <div className="form-group">
                        <div className="lbldesc">Category Title*: </div>
                        <input type="text" ref={categoryTitleRef} className="form-control wfull" required/>
                     </div>
                     <div className="form-group">
                        <div className="lbldesc">Category Information: </div>
                        <textarea ref={categoryTextAreaRef} className="form-control wfull h100f"></textarea>                        
                     </div>
                     <div className="form-group">
                         <button type="submit" className="btn btn-picky" disabled={proc}>Create Category</button>
                     </div>
                </form>
                {
                    proc && (
                        <div className="img-source">
                           <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                        </div>
                    )
                }
            </div>
        </div>
    </Dialog>
  )
}

export default DlgAddCategory
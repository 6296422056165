import React, { useRef, useState } from 'react';
import './login.css';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { Link } from 'react-router-dom';

function ForgotPassword() {

  const [erEmailVal, setEmailVal]                                = useState("");
  const [messgBack, setMessageBack]                              = useState("");
  const [msgClas, setMsgClas]                                    = useState("");
  const [confirmationSent, setConfirmationSent]                  = useState(false);
  const [changePass, setChangePASS]                              = useState(false);
  const [currentCode, setCurrentCode]                            = useState("");
  const [cEmail, setCEmail]                                      = useState("");

  const [processing, setProcessing]                              = useState(false);

  const emailRef                            = useRef();
  const codeFromEmail                       = useRef();
  const newPasswordRef                      = useRef();

  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const executeSendEmail = async () => {
 
    if ( re.test(emailRef.current.value) ) {
        setProcessing(true)

        setEmailVal("");
        try{
            const emailItem = {
                "email": emailRef.current.value
            }
            setCEmail(emailRef.current.value);
            const response = await axios.put(CONSTANTS.API_URL + "auth/forgot", emailItem);
           

            if(response.data.status === "success"){
                
                setMessageBack("")
                setMsgClas("")
               
                var code = (Math.floor(Math.random() * (500 - 100 + 1)) + 100) +""+ (Math.floor(Math.random() * (500 - 100 + 1)) + 100);
                setCurrentCode(code);
                const getSendEmail = await axios.get(CONSTANTS.EMAIL_URL + "forgot_pass="+CONSTANTS.EMAIL_CODE+"&ecode="+code+"&eemail=" + emailRef.current.value);
         
              
                if(getSendEmail.data.status === true){
                    setConfirmationSent(true);
                }
                setProcessing(false);
            }else {
                setMessageBack(response.data.message)
                setMsgClas("alert-danger");
                setProcessing(false)
            }

        }catch(erEmail){
            console.log("Email Err");
            console.log(erEmail);
        }
    }
    else {
        // invalid email, maybe show an error to the user.
        setEmailVal("Email not Valid")
    }
  }

  const executeConfirmationCheck = () => {

       if(codeFromEmail.current.value == currentCode){
             setMessageBack("")
             setMsgClas("")
             setChangePASS(true);
       }else {
            setChangePASS(false);
            setMessageBack("Code is incorrect now")
            setMsgClas("alert-danger")
       }
  }

  const executeUpdatePassword = async () => {
    if(newPasswordRef.current.value.length > 3){
            var emailItem = {
                "email": cEmail,
                "password":newPasswordRef.current.value
            }

            const response = await axios.put(CONSTANTS.API_URL + "auth/passreset", emailItem);
        
            if(response.data.status == "success"){
                setMessageBack(response.data.message)
                setMsgClas("alert-success")
            }else {
                setMessageBack("Password could not be updated. Please try again later")
                setMsgClas("alert-danger")
            }
    }else {
        setMessageBack("Password should be more than 3 characters")
        setMsgClas("alert-danger")
    }
  }

  return (
    <div className="logo-base flexlog">
          <div className="log-start">
            <div className="lgin-spot">
              <h3>Forgot Password</h3>          
                
                <div className="form-group frg">
                  <div className="text-form">Email: </div>
                  <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter the email"/>
                </div>              
                {
                    confirmationSent ? (
                       <div className="item-group">
                            <div className="form-group frg">
                                    <div className="text-form">Confirmation Code*: <strong>Please check your email</strong></div>
                                    <input type="email" className="form-control ct-content wide100" ref={codeFromEmail}  placeholder="Enter the security code" required/>
                            </div>
                            <div className="form-group frg">
                                    <button className="btn btn-redish" onClick={executeConfirmationCheck} disabled={processing}>Confirm</button>
                            </div>
                       </div>
                    )

                    :
                    <div className="form-group frg">
                        <button className="btn btn-redish" onClick={executeSendEmail} disabled={processing}>Reset Password</button>
                    </div>
                }

                {
                    changePass && (
                        <div className="">
                            <div className="form-group frg">
                                <div className="text-form">New Password: </div>
                                <input type="text" className="form-control ct-content wide100" ref={newPasswordRef}  placeholder="Enter the new password"/>
                                <sub>4 plus characters</sub>
                            </div>
                            <div className="form-group frg">
                                <button className="btn btn-redish" onClick={executeUpdatePassword} disabled={processing}>Change Password</button>
                            </div>
                        </div>
                    )
                }
                
                
                <div className="frg">
                  <Link to="/login">
                  Sign In.
                  </Link>
                </div>
          
            
              <div className="frg">
                {erEmailVal && (
                    <div className={"alerter alert-danger"}>
                        {erEmailVal}
                    </div>
                )}
              </div>
              {
                msgClas && (
                    <div className={"alerter " + msgClas}>
                        {messgBack}
                    </div>
                )
              }
              {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                            </div>
                        )
                     }
            </div>
          </div>
          <div className="log-image-pot" style={{
               backgroundImage: `url(${CONSTANTS.LOGIN_BACKGROUND})` }}>        
          </div>
      </div>
  )
}

export default ForgotPassword
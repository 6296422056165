import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";

function DlgAddToken(props) {

    const handleCreateToken = async () => {
        try{
            console.log(props.selectedCatalogue._id);
            console.log("Horrible");

            const res = await axios.post(CONSTANTS.API_URL +"tokens", {
                catalogueId: props.selectedCatalogue._id
            }, {
                headers: {
                    token: "Bearer "+ props.user.accessToken
                }
            });
            
            //console.log(res);
            console.log(res.data);
            props.setDisplayTokenDialog(false);
            props.fetchAlltheTokens();
        }
        catch(errors){
            console.log("Create TOKEN ERROR");
            console.log(errors)
        }
    }

    
    return (
        <Dialog header="Create Token" visible={props.displayTokenDialog} style={{ width: '50vw' }} modal={true} onHide={() => props.setDisplayTokenDialog(false)}
            footer={
                <div>
                    <Button label="Create" icon="pi pi-check" onClick={handleCreateToken} />
                    <Button className="p-button-danger" label="Close" onClick={() => props.setDisplayTokenDialog(false)} />
                </div>
            }>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Dropdown id="catalogue_id" options={props.catalogues} value={props.selectedCatalogue} optionLabel="title" placeholder="Select a Catalogue"
                        onChange={(e) => props.setSelectedCatalogue(e.value)} />
                </div>
            </div>

        </Dialog>
    )

}

export default DlgAddToken;
import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import DlgAddCategory from '../components/DlgAddCategory';
import DlgDelCategory from '../components/DlgDelCategory';
import DlgAddCategoryImage from '../components/DlgAddCategoryImage';
import { useSelector } from 'react-redux';

function Categories() {

    const {user}                                                = useSelector((state) => state.auth);
    
    const [processing, setProcessing]                           = useState(false);
    const [categoryList, setCategoryList]                       = useState([]);
    const [categoCount, setCategoCount]                         = useState();
    const [categSearched, setCategSearched]                     = useState(false)
    const [dsplyCategoryDialog, setDsplyCategoryDialog]         = useState(false);
    const [dsplyImgCategoryDialog, setDsplyImgCategoryDialog]   = useState(false);
    const [delCategoryDialog, setDelCategoryDialog]             = useState(false);
    const [currentCat, setCurrentCat]                           = useState(null);
    const [parentID, setParentID]                               = useState(0);
    const [parentName, setParentName]                           = useState("");
    const [catadded, setCatAdded]                               = useState(0);

    useEffect(() => {
        getAllCategories();
    },[catadded]);


    const getAllCategories = async () => {
        setProcessing(true);
       
        try{

            const resCata = await axios.get(CONSTANTS.API_URL + "categories/list" , {
                headers: {
                  token: "Bearer " + user.accessToken
                }
              });
              setCategSearched(true)
              //console.log(resCata.data.data);
              setCategoryList(resCata.data.data);
              setCategoCount(resCata.data.data.length);
              setProcessing(false) 
        }catch(ers){
            console.log(ers);
            console.log("Fetch Er");
            setProcessing(false);
            if(ers.message === "Request failed with status code 403"){
              localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
              document.location.href="/login";
             }
        }
      }
     
      const addMainCategory = () => {
        setCurrentCat(null);
        setDsplyCategoryDialog(true);
      }
    
      const createSubCategory = (catItem) => {
        //console.log("THIS WAS CLICKED");
        //console.log(catItem);
        setCurrentCat(catItem);
        setDsplyCategoryDialog(true);
      }
      const deleteCurrentCategory = (catItem) => {
        console.log(catItem);
        setCurrentCat(catItem);
        setParentID(0);
        setDelCategoryDialog(true);
      }

      const addCatImage = (catItem) => {
        //console.log("addCatImage THIS WAS CLICKED");
        console.log(catItem);
        
        setCurrentCat(catItem);
        setDsplyImgCategoryDialog(true);
        //setCurrentCat(catItem);
        //setDsplyCategoryDialog(true);
      }

      const deleteSingleCurrentCategory = (catSub, cMainName, ctMainID) => {
         console.log(ctMainID);
         console.log(catSub);
         setParentID(ctMainID);
         setCurrentCat(catSub);
         setParentName(cMainName);
         setDelCategoryDialog(true);
      }

  return (
    <div className="main-standard">
           <h2 className="lbl-title-page">Categories</h2>
                <div className="card">
                    {
                        categoCount && (
                          <h3 className="totCat">Total Categories {categoCount}</h3>
                        )
                    }
                    <div className="scroll-larger ">
                         
                        {
                            categoryList && (
                              <ul className="no-bullet cat-box">
                              {categoryList.map((catitem, i) => {
                                return (
                                  <li key={catitem._id} className="ln-item">
                                      <div className="flexme">
                                        <div className="block-wide-a">
                                          <div className="title-up"><span>{1 + i}</span> {catitem.categoryName}</div>
                                          <div className="sub-text-inf pdtb10">
                                            {catitem.categoryDescription}
                                          </div>
                                        </div>
                                        <div className="block-count"> cat NO: {catitem.sub_categories.length}</div>
                                        <div className="block-b">
                                          <button className="btn btn-picky btsm1" onClick={() => createSubCategory(catitem)}>+</button>
                                          <button className="btn btn-blue btsm1" onClick={() => deleteCurrentCategory(catitem)}>-</button>
                                          <button className="btn btn-picky btsm1" onClick={() => addCatImage(catitem)}>IMG</button>
                                        </div>
                                      </div>
                                      {(catitem.sub_categories.length > 0) && (
                                          <ul className="no-bullet cat-sub">
                                            {
                                              catitem.sub_categories.map((catSub, i) => {
                                                return (
                                                  <li key={i} className="ln-sub">
                                                    <div className="flexme">
                                                      <div className="block-wide-a suby-cont">
                                                        {catSub.name}
                                                        <div className="sub-text-inf">
                                                          {catSub.description}
                                                        </div>
                                                      </div>
                                                      <div className="block-b">   
                                                      {
                                                        /* <button className="btn btn-green btsm1" onClick={() => deleteSingleCurrentCategory(catSub, catitem.categoryName, catitem._id)}>-</button> */
                                                      } 
                                                                                            
                                                      </div>
                                                    </div>
                                                  </li>
                                                )
                                              })
                                            }
                                          </ul>
                                      )}
                                  </li>
                                )
                              })}
                              </ul>
                            )
                        }

                        {
                            processing && (
                                <div className="img-source">
                                <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                                </div>
                            )
                        }
                    </div>
                     <div className="but-small pd10 mt-3 lg:mt-0">
                        <Button
                          label="Add Category"
                          className="btn btn-picky btsm1 mr-2"
                          onClick={() => addMainCategory()}
                        />
                     </div>

                    <DlgAddCategory dsplyCategoryDialog={dsplyCategoryDialog} setDsplyCategoryDialog={setDsplyCategoryDialog} currentCat={currentCat} person={user} setCatAdded={setCatAdded} catadded={catadded}/>
                    
                    <DlgDelCategory delCategoryDialog={delCategoryDialog} setDelCategoryDialog={setDelCategoryDialog} currentCat={currentCat} parentID={parentID} parentName={parentName} person={user} />

                    <DlgAddCategoryImage 
                      dsplyImgCategoryDialog={dsplyImgCategoryDialog}
                      setDsplyImgCategoryDialog={setDsplyImgCategoryDialog} 
                      currentCat={currentCat}
                      person={user} />
                </div>
    </div>
  )
}

export default Categories
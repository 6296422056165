import React, { useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";


function TBVSetPresentation({person}) {

    const [msgFeed, setMsgFeedback]                      = useState("");
    const [msgClass, setMsgClass]                        = useState("");
    const [presents, setPresentation]                    = useState([]);
    const [presentAction, setPresentationAct]            = useState(0);

    const [processing, setProcessing]                    = useState(false);

    const titleRef                                 = useRef();
    const notesRef                                 = useRef();

    useEffect(()=> {
        getListOfPresentations();
    },[presentAction]);

    const getListOfPresentations = async () => {
        try{

            const resPresent = await axios.get(CONSTANTS.API_URL+"pictures/presentations", {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
              });
              //console.log(resPresent.data);
              setPresentation(resPresent.data);

        }catch(ers){
            console.log(ers);
            console.log("ERR");
            if(ers.message === "Request failed with status code 403"){
                localStorage.removeItem("user_picknic2");
                document.location.href="/login";
               }
        }
    }
    const deleteCurrent = async(current) => {
       try{
       // console.log(current);
        const resPresentDele = await axios.delete(CONSTANTS.API_URL+"pictures/presdelete/"+ current._id, {
            headers: {
                token: "Bearer "+ person.accessToken
            }
          });

       // console.log(resPresentDele.data);
        setPresentationAct(presentAction + 1);
       }catch(ers){
        console.log(ers);
       }
    }

    async function handlePresentationAdd(e){
        e.preventDefault();  
        setProcessing(true);
        if(titleRef.current.value.length > 0){
          
              const dataObs = {
                "title": titleRef.current.value,
                "note": notesRef.current.value
              }
              
              //console.log(dataObs);
              const response = await axios.put(CONSTANTS.API_URL+"pictures/present", dataObs, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
              });
              /*
              console.log("XXXXXXXXXXXX")
              console.log(response.data);
              */
              setMsgFeedback(response.data.status);
              setMsgClass("alert-success"); 
              setProcessing(false);
              titleRef.current.value = "";
              notesRef.current.value = "";
              setPresentationAct(presentAction + 1);
           
        } else {
          setMsgFeedback("Password cannot be less than 4 characters");
          setMsgClass("alert-danger"); 
          setProcessing(false);
        }
        
      }
  return (
    <div className="tv-box">
        <h3>Presentation</h3>
        <div className="base copy">            
            <div className="copy-box">
                <h4>Presentation List</h4>
                {
                    presents && (
                       <div className="list-blow">
                            <ul className="no-bullet">
                                {presents.map( (pres) => {
                                    return (
                                        <li className="mong-item item" key={pres._id}>
                                        <span> {pres.title}</span>
                                            <span className="flright"><button className="btn btn-picky btsm1 tpmin5" onClick={() => deleteCurrent(pres)}>X</button></span>
                                        </li>
                                    )
                                })}
                            </ul>
                       </div>
                    )
                }
            </div>
            <hr/>
            <div className="copy-box">
                <h4>Add Presentation</h4>
               <div className="form-block">
                {msgClass && (
                    <div className={"alert " + msgClass}>
                        {msgFeed}
                    </div>
                )}
                    <form onSubmit={handlePresentationAdd}>
                        <div className="form-group pd10">
                            <div className="lbldesc">Title*:</div>
                            <input type="text" className="form-control wide100" ref={titleRef} required/>                            
                        </div>
                        <div className="form-group pd10">
                            <div className="lbldesc">Notes:</div>
                            <input type="text" className="form-control wide100" ref={notesRef} />                            
                        </div>
                        <div className="form-group pd10">
                            <button type="submit" className="btn btn-picky">Send</button>
                        </div>    
                    </form>
               </div>

            </div>
            {
                processing && (
                    <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default TBVSetPresentation